import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Item, BundleItem } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { getItem, getBundleItems } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";

type Value = {
	id: number;
	label: string;
	key: string;
};

const values: Value[] = [
	{ id: 1, label: "Item Type", key: "itemTypeName" },
	{ id: 2, label: "Item Category", key: "itemCategory" },
	{ id: 3, label: "Brand Category", key: "brandCategoryName" },
	{ id: 4, label: "Product Category", key: "productCategoryName" },
	{ id: 5, label: "Brand Code", key: "brandCode" },
	{ id: 6, label: "Parent Code", key: "parentCode" },
	{ id: 7, label: "Item Code", key: "itemCode" },
	{ id: 8, label: "Item Name", key: "itemName" },
	{ id: 9, label: "UOM", key: "uomGroup" },
	{ id: 10, label: "Size", key: "sizeName" },
	{ id: 11, label: "Target Days Cover", key: "classificationName" },
	{ id: 12, label: "Purchase Price", key: "purchasePrice" },
	{ id: 13, label: "Currency", key: "currencyName" },
	{ id: 14, label: "Suggested Retail Price", key: "suggestedRetailPrice" },
];

const bundleValues: Value[] = [
	{ id: 1, label: "Item Name", key: "itemName" },
	{ id: 2, label: "Item Code", key: "itemCode" },
	{ id: 3, label: "Quantity", key: "quantity" },
];

export default function ViewItem() {
	const [item, setItem] = useState<Item | null>(null);
	const [bundleItems, setBundleItems] = useState<BundleItem[]>([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
		if (params.id) {
			console.log("Here");
            fetchItem(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchItem = async (id: number) => {
        try {
            const response = await getItem(id);
			if (response?.success) {
				const item = response?.result as Item ?? null;
				setItem(item);
				if (item.itemCategory?.toUpperCase() === "BUNDLE") {
					const bundleResponse = await getBundleItems(id);
					if (bundleResponse?.success) {
						const bundleItems = bundleResponse?.result?.list as BundleItem[] ?? [];
						setBundleItems(bundleItems);
					}
				}
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item details");
        }
    };
	
	return (
    	<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className="text-left text-2xl font-Poppins font-semibold ml-5">
					View Item
				</span>
			</div>
        	{item ? (
            	<form className='grid grid-cols-3 gap-4 py-6 justify-center items-center'>
					{values?.map((value: Value) => (
						<div key={value.id}>
                			<h2 className="text-sm font-Poppins font-base mb-2">{value?.label}</h2>
                   			<TextField
								variant="outlined"
								defaultValue={item[value.key as keyof typeof item]}
								disabled
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={{
									width: '100%',
									'& .MuiOutlinedInput-root': {
										borderRadius: '0.5rem',
										height: '44px',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: 'transparent',
									},
								}}
								className='bg-slate-100 dark:bg-[#202829] rounded-lg'
							/> 
                		</div>
					))}
				</form>
			) : (
				<p>Loading</p>
			)}
			{bundleItems?.length > 0 && (
				<div>
					<h2 className="text-left text-xl font-Poppins font-semibold">Bundle Items</h2>
					{bundleItems?.map((bundle: BundleItem) => (
						<div key={bundle.itemId} className="grid grid-cols-3 gap-4 justify-center items-center mt-5">
							{bundleValues?.map((value: Value) => (
								<div key={value.id}>
									<h2 className="text-sm font-Poppins font-base mb-2">{value?.label}</h2>
									<TextField
										variant="outlined"
										defaultValue={bundle[value.key as keyof typeof bundle]}
										disabled
										inputProps={{ className: 'dark:text-white text-black' }}
										sx={{
											width: '100%',
											'& .MuiOutlinedInput-root': {
												borderRadius: '0.5rem',
												height: '44px',
											},
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'transparent',
											},
										}}
										className='bg-slate-100 dark:bg-[#202829] rounded-lg'
									/>	
								</div>
							))}
						</div>
					))}
				</div>
			)}
		</main>
	);
};
