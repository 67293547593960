import { useEffect, useState } from 'react';
import {
	TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { Role, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = [ "Role Name", "Access", ""];
const limit = 10;

export default function Roles({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getRoles, queryKey: roleKey } = queryConfigs.getRoles;
    const { mutationFn: deleteRole } = queryConfigs.deleteRole;
    const [searchValue, setSearchValue] = useState("");
    const [searchForm, setSearchForm] = useState({ name: "" });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState<number | null>(null);
    const [roleList, setRoleList] = useState<Role[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Role Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: roles, refetch } = useGetQuery({
        key: roleKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            name: searchForm.name ?? "",
        },
        func: getRoles,
    });

    const mutateDelete = useMutationQuery({ key: roleKey, func: deleteRole, onSuccess });

    useEffect(() => {
        if (roles) {
            const list = roles?.result?.list as Role[];
            setRoleList(list);
        }
    }, [roles]);

    const handleAddClick = () => {
        navigate('add-role');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-role/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setRoleToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (roleToDelete) {
            mutateDelete.mutate(roleToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            name: searchValue ?? ""
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchForm({ name: "" });
	};

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    const getAccess = (role: Role): string => {
        const arr: string[] = [];
        if (role.hasView) {
            arr.push("View");
        }
        if (role.hasAdd) {
            arr.push("Add");
        }
        if (role.hasEdit) {
            arr.push("Edit");
        }
        if (role.hasDelete) {
            arr.push("Delete");
        }
        return arr.join(", ");
      }

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(roles?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				cred={cred}
                children1={
					<>
                    	<div className=" flex items-center space-x-5 w-full">
                    		<TextField
								variant="outlined"
								placeholder='Enter Role Name'
								name="name"
								value={searchValue}
								onChange={handleNameChange}
								size="small"
								inputProps={{
									className: 'dark:text-white text-black',
								}}
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										borderRadius: '0.5rem',
										height: '44px',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: 'transparent',
									},
								}}
								className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                        	/>      
                        	<button onClick={handleSearch}  className="search-btn">
                          		Search
                        	</button>
                        	<button onClick={handleSearchClear}  className="clear-btn">
                          		Clear
                        	</button>
                      	</div>
                    </>
            	}
            >
                {roleList?.map((role: Role) =>(
                    <TableRow key={role.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px', fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{role.name}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{getAccess(role)}</TableCell>
                        <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
                            <div className='flex justify-center items-center space-x-2'>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
                                    	<button className="edit-btn" onClick={() => handleEditClick(role.id)}>
                                        	<FaEdit />
                                    	</button>
                                	</Tooltip>
								}
								{cred?.hasDelete === 1 &&
                                	<Tooltip title="Delete" arrow>
                                    	<button className="del-btn" onClick={() => handleDeleteClick(role.id)}>
                                        	<MdDeleteForever />
                                    	</button>
                                	</Tooltip>
								}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
				text="Are you sure you want to delete this Role?"
			/>
        </div>
    );
};
