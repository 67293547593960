import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField, Tooltip,
	Modal, Box,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { BrandCategory, Item, ProductCategory, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import { PiEyeFill } from 'react-icons/pi';
import { getBrandCategories, getProductCategories, getExportURL } from '../../lib/api';
import Import from "../Import";
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = [
    "Item Type", "Brand Category", "Product Category", "Brand Code",
    "Parent Code", "Item Code", "Item Name", "Item Category",
    "UOM", "Size", "Target Days Cover", "Purchased Price",
    "Currency", "Landed COGS", "Suggested Retail Price",
    "Net Sales", "COGS %", "GP %", "COGS To SRP", "",
];
const limit = 10;

export default function Items({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getItems, queryKey: itemKey } = queryConfigs.getItems;
    const { mutationFn: deleteItem } = queryConfigs.deleteItem;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("brandCategory");
    const [searchForm, setSearchForm] = useState({
        name: "", itemCode: "", parentCode: "",
        brandCategory: "", productCategory: "",
    });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [ brandCategories, setBrandCategories ] = useState<BrandCategory[]>([]);
    const [ productCategories, setProductCategories ] = useState<ProductCategory[]>([]);
    const [itemToDelete, setItemToDelete] = useState<number | null>(null);
    const [itemList, setItemList] = useState<Item[]>([])
	const [currentPage, setCurrentPage] = useState(1);
	const [openImport, setOpenImport] = useState(false);
    const { theme } = useTheme();

	const boxStyles = {
		position: 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: theme === 'dark' ? '#20293D' : 'white',
		color: theme === 'dark' ? 'white' : 'black',
		borderRadius: '10px',
		boxShadow: 24,
	};

    const onSuccess = () => {
        showNotification("success", "Item Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: items, refetch } = useGetQuery({
        key: itemKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            name: searchForm?.name ?? "",
            itemCode: searchForm?.itemCode ?? "",
            parentCode: searchForm?.parentCode ?? "",
            brandCategory: searchForm?.brandCategory ?? "",
            productCategory: searchForm?.productCategory ?? "",
        },
        func: getItems,
    });

    const mutateDelete = useMutationQuery({ key: itemKey, func: deleteItem, onSuccess });

    useEffect(() => {
        fetchBrandCategories();
        fetchProductCategories();
    }, []);

    const fetchBrandCategories = async () => {
        try {
            const response = await getBrandCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setBrandCategories (response?.result?.list as BrandCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
    
    const fetchProductCategories = async () => {
        try {
            const response = await getProductCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setProductCategories (response?.result?.list as ProductCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (items) {
            const list = items?.result?.list as Item[];
            setItemList(list);
        }
    }, [items]);

    const handleAddClick = () => {
        navigate('add-item');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-item/${id}`);
    };

    const handleViewClick = (id: number) => {
        navigate(`view-item/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setItemToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (itemToDelete) {
            mutateDelete.mutate(itemToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
      };
    
    const handleSearch = () => {
        setSearchForm({
            name: searchType === "name" ? searchValue : "",
            itemCode: searchType === "itemCode" ? searchValue : "",
            parentCode: searchType === "parentCode" ? searchValue : "",
            brandCategory: searchType === "brandCategory" ? searchValue : "",
            productCategory: searchType === "productCategory" ? searchValue : "",
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("salesCategory");
        setSearchForm({ name: "", itemCode: "", parentCode: "", productCategory: "", brandCategory: "" });
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

	const exportHandler = () => {
		let url: string = getExportURL("items");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&parentCode=${searchForm?.parentCode ?? ""}&
			brandCategory=${searchForm?.brandCategory ?? ""}&productCategory=${searchForm?.productCategory ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(items?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
                componentType='item'
                reload={handleReload}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="name" style={{ fontFamily: 'Poppins' }}>Name</MenuItem>
                                    <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                    <MenuItem value="parentCode" style={{ fontFamily: 'Poppins' }}>Parent Code</MenuItem>
                                    <MenuItem value="brandCategory" style={{ fontFamily: 'Poppins' }}>Brand Category</MenuItem>
                                    <MenuItem value="productCategory" style={{ fontFamily: 'Poppins' }}>Product Category</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["name", "itemCode", "parentCode"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                            }}
                                            sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            }}
                                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"brandCategory".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Brand Category</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {brandCategories?.map((item: BrandCategory) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {"productCategory".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Product Category</h2>
                                    <FormControl sx={{ width: "50%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {productCategories?.map((item: ProductCategory) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {itemList?.map((item: Item) =>(
                    <TableRow key={item.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.itemTypeName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.brandCategoryName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.productCategoryName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.brandCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.parentCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{item.itemCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{item.itemName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.itemCategory}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.uomGroup}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.sizeName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.classificationName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.purchasePrice}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.currencyName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.landedCogs}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.suggestedRetailPrice}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.netSales}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.cogs} %</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{item.gp} %</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">  {item.cogsToSrp} %
                        </TableCell>
                        <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
                            <div className='flex justify-center items-center space-x-2'>
								<Tooltip title="View" arrow>
                                    <button className="edit-btn" onClick={() => handleViewClick(item.id)}>
                                        <PiEyeFill />
                                    </button>
                                </Tooltip>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
										<button className="edit-btn" onClick={() => handleEditClick(item.id)}>
											<FaEdit />
										</button>
									</Tooltip>
								}
								{cred?.hasDelete === 1 &&
									<Tooltip title="Delete" arrow>
										<button className="del-btn" onClick={() => handleDeleteClick(item.id)}>
											<MdDeleteForever />
										</button>
									</Tooltip>
								}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Item?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={boxStyles} >
					<Import fileType="items" refresh={refetch} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
