import toast from "react-hot-toast";
import { LoginResult } from "./models";

export const debouncer = function(func: (e: any, value: string) => void, wait: number) {
    let timeout: any;
    return function(e: any, value: string){
        const context = this;
        const later = function() {
            timeout = null;
            func.apply(context, [e, value]);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export function showNotification(type: string, message: string) {
    if(type === "success") {
        toast.success(message, { duration: 2000, position: "top-center" });
    } else if (type === "error") {
        toast.error(message, { duration: 2000, position: "top-center" });
    }
};

export function isEmpty(value: any): boolean {
    return value === null || value === undefined || value.toString().trim().length === 0;
};

export function sanitizeValue(value: any): number {
	return isEmpty(value) ? 0 : isNaN(value) ? 0 : Number(value);
};


export const setItem = (key: string, value: LoginResult) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key: string) => {
    return localStorage.getItem(key);
};

export const deleteItem = (key: string) => {
    localStorage.removeItem(key);
};

export const deleteAll = () => {
    localStorage.clear();
};
