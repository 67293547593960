import axios, {AxiosError} from "axios";
import { ApiResponse, ApiSingleResponse } from './models';
import { QueryParams } from './common';
import { Methods } from "./common";
import {
	BrandCategoryPayload, BrandPayload, ChannelPayload, ClassificationPayload,
	CompanyPayload, CountryPayload, CurrencyPayload, CustomerPayload, DemandPayload,
	InboundPayload, ItemPayload, ItemTypePayload, LoginPayload, OutboundPayload,
	ProductCategoryPayload, RolePayload, SalesCategoryPayload, SizePayload,
	TransferPayLoad, UomPayload, UserPayload, VendorPayload, WarehousePayload,
} from "./payload";

axios.defaults.baseURL = "https://qa.codexp.in/api/v1";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

export const getExportURL = (reportName: string) => `https://qa.codexp.in/api/v1/downloads/${reportName}`
export const getTemplateURL = (templateName: string) => `https://qa.codexp.in/api/v1/downloads/templates?fileType=${templateName}`;

export const setAuthHeader = (token: string | null) => {
    axios.defaults.headers["Authorization"] = token;
    return true;
};

export const clearAuthHeader = () => {
    axios.defaults.headers["Authorization"] = null;
    return true;
};

/* DASHBOARD APIs */
export const getDashboard = (): Promise<ApiResponse> => _callApi(`/dashboard`, "get");
export const getDashboards = ({ offset, limit, warehouse, itemCode, itemName, stockStatus, brandCode }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/dashboard?offset=${offset ?? 0}&limit=${limit ?? 100}&warehouse=${warehouse || ""}&itemCode=${itemCode || ""}
	&itemName=${itemName || ""}&stockStatus=${stockStatus || ""}&brandCode=${brandCode || ""}`, 'get');

/* AUTHENTICATION APIs */
export const login = (body : LoginPayload ): Promise<ApiSingleResponse> => _callApi(`/auth/login`, 'post', body);
export const logout = (): Promise<ApiSingleResponse> => _callApi(`/auth/logout`, "post");
export const forgotPassword = (body: LoginPayload): Promise<ApiSingleResponse> => _callApi(`/auth/forgotpassword`, "post", body);

/* PROFILE APIs */
export const getProfile = (): Promise<ApiSingleResponse> => _callApi(`/users/profile/data`, "get");

/* COMPANY APIs */
export const getCompanies = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/companies?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getCompany = (id: number): Promise<ApiSingleResponse> => _callApi(`/companies/${id}`, "get");
export const addCompany = (body: CompanyPayload): Promise<ApiResponse> => _callApi(`/companies`, "post", body);
export const updateCompany = ({ id, body }: { id: number, body: CompanyPayload }): Promise<ApiResponse> =>
	_callApi(`/companies/${id}`, "put", body);
export const deleteCompany = (id: number) => _callApi(`/companies/${id}`, "delete");

/* ROLE APIs */
export const getRoles = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/roles?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getRole = (id: number): Promise<ApiSingleResponse> => _callApi(`/roles/${id}`, "get");
export const addRole = (body: RolePayload): Promise<ApiResponse> => _callApi(`/roles`, "post", body);
export const updateRole = ({ id, body }: { id: number, body: RolePayload }): Promise<ApiResponse> =>
	_callApi(`/roles/${id}`, "put", body);
export const deleteRole = (id: number) => _callApi(`/roles/${id}`, "delete");

/* USER APIs */
export const getUsers = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/users?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getUser = (id: number): Promise<ApiSingleResponse> => _callApi(`/users/${id}`, "get");
export const addUser = (body: UserPayload): Promise<ApiResponse> => _callApi(`/users`, "post", body);
export const updatePassword = (body: UserPayload): Promise<ApiResponse> => _callApi(`/users/updatepassword`, "post", body);
export const updateUser = ({ id, body }: { id: number, body: UserPayload }): Promise<ApiResponse> =>
	_callApi(`/users/${id}`, "put", body);
export const deleteUser = (id: number) => _callApi(`/users/${id}`, "delete");

/* ITEMTYPE APIs */
export const getItemTypes = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/item-types?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getItemType = (id: number): Promise<ApiSingleResponse> => _callApi(`/item-types/${id}`, "get");
export const addItemType = (body: ItemTypePayload): Promise<ApiResponse> => _callApi(`/item-types`, "post", body);
export const updateItemType = ({ id, body }: { id: number, body: ItemTypePayload }): Promise<ApiResponse> =>
	_callApi(`/item-types/${id}`, "put", body);
export const deleteItemType = (id: number) => _callApi(`/item-types/${id}`, "delete");

/* SALES-CATEGORY APIs */
export const getSalesCategories = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/sales-categories?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getSalesCategory = (id: number): Promise<ApiSingleResponse> => _callApi(`/sales-categories/${id}`, "get");
export const addSalesCategory = (body: SalesCategoryPayload): Promise<ApiResponse> => _callApi(`/sales-categories`, "post", body);
export const updateSalesCategory = ({ id, body }: { id: number, body: SalesCategoryPayload }): Promise<ApiResponse> =>
	_callApi(`/sales-categories/${id}`, "put", body);
export const deleteSalesCategory = (id: number) => _callApi(`/sales-categories/${id}`, "delete");

/* CHANNEL APIs */
export const getChannels = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/channels?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getChannel = (id: number): Promise<ApiSingleResponse> => _callApi(`/channels/${id}`, "get");
export const addChannel = (body: ChannelPayload): Promise<ApiResponse> => _callApi(`/channels`, "post", body);
export const updateChannel = ({ id, body }: { id: number, body: ChannelPayload }): Promise<ApiResponse> =>
	_callApi(`/channels/${id}`, "put", body);
export const deleteChannel = (id: number) => _callApi(`/channels/${id}`, "delete");

/* CUSTOMER APIs */
export const getCustomers = ({ offset, limit, name}: QueryParams): Promise<ApiResponse> =>
	_callApi(`/customers?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getCustomer = (id: number): Promise<ApiSingleResponse> => _callApi(`/customers/${id}`, "get");
export const addCustomer = (body: CustomerPayload): Promise<ApiResponse> => _callApi(`/customers`, "post", body);
export const updateCustomer = ({ id, body }: { id: number, body: CustomerPayload }): Promise<ApiResponse> =>
	_callApi(`/customers/${id}`, "put", body);
export const deleteCustomer = (id: number) => _callApi(`/customers/${id}`, "delete");

/* VENDOR APIs */
export const getVendors = ({ offset, limit, code }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/vendors?offset=${offset ?? 0}&limit=${limit ?? 100}&code=${code || ""}`, 'get');
export const getVendor = (id: number): Promise<ApiSingleResponse> => _callApi(`/vendors/${id}`, "get");
export const addVendor = (body: VendorPayload): Promise<ApiResponse> => _callApi(`/vendors`, "post", body);
export const updateVendor = ({ id, body }: { id: number, body: VendorPayload }): Promise<ApiResponse> =>
	_callApi(`/vendors/${id}`, "put", body);
export const deleteVendor = (id: number) => _callApi(`/vendors/${id}`, "delete");

/* COUNTRY APIs */
export const getCountries = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/countries?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getCountry = (id: number): Promise<ApiSingleResponse> => _callApi(`/countries/${id}`, "get");
export const addCountry = (body: CountryPayload): Promise<ApiResponse> => _callApi(`/countries`, "post", body);
export const updateCountry = ({ id, body }: { id: number, body: CountryPayload }): Promise<ApiResponse> =>
	_callApi(`/countries/${id}`, "put", body);
export const deleteCountry = (id: number) => _callApi(`/countries/${id}`, "delete");

/* BRANDCATEGORY APIs */
export const getBrandCategories = ({ offset, limit, name }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/brand-categories?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}`, 'get');
export const getBrandCategory = (id: number): Promise<ApiSingleResponse> => _callApi(`/brand-categories/${id}`, "get");
export const addBrandCategory = (body: BrandCategoryPayload): Promise<ApiResponse> =>
	_callApi(`/brand-categories`, "post", body);
export const updateBrandCategory = ({ id, body }: { id: number, body: BrandCategoryPayload }): Promise<ApiResponse> =>
	_callApi(`/brand-categories/${id}`, "put", body);
export const deleteBrandCategory = (id: number) => _callApi(`/brand-categories/${id}`, "delete");

/* PRODUCTCATEGORY APIs */
export const getProductCategories = ({ offset, limit, name, itemType, brandCategory }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/product-categories?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${name || ""}&itemType=${itemType || ""}&brandCategory=${brandCategory || ""}`, 'get');
export const getProductCategory = (id: number): Promise<ApiSingleResponse> =>
	_callApi(`/product-categories/${id}`, "get");
export const addProductCategory = (body: ProductCategoryPayload): Promise<ApiResponse> =>
	_callApi(`/product-categories`, "post", body);
export const updateProductCategory = ({ id, body }: { id: number, body: ProductCategoryPayload }): Promise<ApiResponse> =>
	_callApi(`/product-categories/${id}`, "put", body);
export const deleteProductCategory = (id: number) => _callApi(`/product-categories/${id}`, "delete");

/* BRAND APIs */
export const getBrands = ({ offset, limit, code, name, company }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/brands?offset=${offset ?? 0}&limit=${limit ?? 100}&code=${code || ""}&name=${name || ""}&company=${company || ""}`, 'get');
export const getBrand = (id: number): Promise<ApiSingleResponse> => _callApi(`/brands/${id}`, "get");
export const addBrand = (body: BrandPayload): Promise<ApiResponse> => _callApi(`/brands`, "post", body);
export const updateBrand = ({ id, body }: { id: number, body: BrandPayload }): Promise<ApiResponse> =>
	_callApi(`/brands/${id}`, "put", body);
export const deleteBrand = (id: number) => _callApi(`/brands/${id}`, "delete");

/* UOM APIs */
export const getUoms = ({ offset, limit, group }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/uom?offset=${offset ?? 0}&limit=${limit ?? 100}&group=${group || ""}`, 'get');
export const getUom = (id: number): Promise<ApiSingleResponse> => _callApi(`/uom/${id}`, "get");
export const addUom = (body: UomPayload): Promise<ApiResponse> => _callApi(`/uom`, "post", body);
export const updateUom = ({ id, body }: { id: number, body: UomPayload }): Promise<ApiResponse> =>
	_callApi(`/uom/${id}`, "put", body);
export const deleteUom = (id: number) => _callApi(`/uom/${id}`, "delete");

/* ITEM APIs */
export const getItems = ({ offset, limit, name, itemCode, parentCode, productCategory, brandCategory  }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/items?offset=${offset ?? 0}&limit=${limit ?? 100}&name=${ name || ""}
		&itemCode=${ itemCode || ""}&parentCode=${ parentCode || ""}
		&productCategory=${ productCategory || ""}&brandCategory=${ brandCategory || ""}`, 'get'
	);
export const getBundleItems = (id: number): Promise<ApiResponse> => _callApi(`/items/${id}/bundles`, "get");
export const getItem = (id: number): Promise<ApiSingleResponse> => _callApi(`/items/${id}`, "get");
export const addItem = (body: ItemPayload): Promise<ApiResponse> => _callApi(`/items`, "post", body);
export const updateItem = ({ id, body }: { id: number, body: ItemPayload }): Promise<ApiResponse> =>
	_callApi(`/items/${id}`, "put", body);
export const deleteItem = (id: number) => _callApi(`/items/${id}`, "delete");
export const deleteBundleItem = (packItemId: number, itemId: number) => _callApi(`/items/${packItemId}/bundles/${itemId}`, "delete");

/* CLASSIFICATION APIs */
export const getClassifications = ({ offset, limit, classification }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/classifications?offset=${offset ?? 0}&limit=${limit ?? 100}&classification=${ classification || ""}`, 'get');
export const getClassification = (id: number): Promise<ApiSingleResponse> =>
	_callApi(`/classifications/${id}`, "get");
export const addClassification = (body: ClassificationPayload): Promise<ApiResponse> =>
	_callApi(`/classifications`, "post", body);
export const updateClassification = ({ id, body }: { id: number, body: ClassificationPayload }): Promise<ApiResponse> =>
	_callApi(`/classifications/${id}`, "put", body);
export const deleteClassification = (id: number) => _callApi(`/classifications/${id}`, "delete");

/* WAREHOUSE APIs */
export const getWarehouses = ({ offset, limit, code }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/warehouses?offset=${offset ?? 0}&limit=${limit ?? 100}&code=${code || ""}`, 'get');
export const getWarehouse = (id: number): Promise<ApiSingleResponse> => _callApi(`/warehouses/${id}`, "get");
export const addWarehouse = (body: WarehousePayload): Promise<ApiResponse> => _callApi(`/warehouses`, "post", body);
export const updateWarehouse = ({ id, body }: { id: number, body: WarehousePayload }): Promise<ApiResponse> =>
	_callApi(`/warehouses/${id}`, "put", body);
export const deleteWarehouse = (id: number) => _callApi(`/warehouses/${id}`, "delete");

/* SIZE APIs */
export const getSizes = ({ offset, limit, size }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/sizes?offset=${offset ?? 0}&limit=${limit ?? 100}&size=${size || ""}`, 'get');
export const getSize = (id: number): Promise<ApiSingleResponse> => _callApi(`/sizes/${id}`, "get");
export const addSize = (body: SizePayload): Promise<ApiResponse> => _callApi(`/sizes`, "post", body);
export const updateSize = ({ id, body }: { id: number, body: SizePayload }): Promise<ApiResponse> =>
	_callApi(`/sizes/${id}`, "put", body);
export const deleteSize = (id: number) => _callApi(`/sizes/${id}`, "delete");

/* CURRENCY APIs */
export const getCurrencies = ({ offset, limit, currency }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/currencies?offset=${offset ?? 0}&limit=${limit ?? 100}&currency=${currency || ""}`, 'get');
export const getCurrency = (id: number): Promise<ApiSingleResponse> => _callApi(`/currencies/${id}`, "get");
export const addCurrency = (body: CurrencyPayload): Promise<ApiResponse> => _callApi(`/currencies`, "post", body);
export const updateCurrency = ({ id, body }: { id: number, body: CurrencyPayload }): Promise<ApiResponse> =>
	_callApi(`/currencies/${id}`, "put", body);
export const deleteCurrency = (id: number) => _callApi(`/currencies/${id}`, "delete");

/* INBOUND APIs */
export const getInbounds = ({ offset, limit, warehouse, itemCode, itemName, vendor, poNumber, startDate, endDate }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/inbounds?offset=${offset ?? 0}&limit=${limit ?? 100}&warehouse=${warehouse || ""}&
		itemCode=${itemCode || ""}&itemName=${itemName || ""}&vendor=${vendor || ""}&startDate=${startDate || ""}&
		endDate=${endDate || ""}&poNumber=${poNumber || ""}`, 'get'
	);
export const getInbound = (id: number): Promise<ApiSingleResponse> => _callApi(`/inbounds/${id}`, "get");
export const addInbound = (body: InboundPayload): Promise<ApiResponse> => _callApi(`/inbounds`, "post", body);
export const updateInbound = ({ id, body }: { id: number, body: InboundPayload }): Promise<ApiResponse> => _callApi(`/inbounds/${id}`, "put", body);
export const deleteInbound = (id: number) => _callApi(`/inbounds/${id}`, "delete");

/* OUTBOUND APIs */
export const getOutbounds = ({ offset, limit, warehouse, itemCode, itemName, customer, poNumber, startDate, endDate }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/outbounds?offset=${offset ?? 0}&limit=${limit ?? 100}&warehouse=${warehouse || ""}&
		itemCode=${itemCode || ""}&itemName=${itemName || ""}&customer=${customer || ""}&
		startDate=${startDate || ""}&endDate=${endDate || ""}&poNumber=${poNumber || ""}`, 'get'
	);
export const getOutbound = (id: number): Promise<ApiSingleResponse> => _callApi(`/outbounds/${id}`, "get");
export const addOutbound = (body: OutboundPayload): Promise<ApiResponse> => _callApi(`/outbounds`, "post", body);
export const updateOutbound = ({ id, body }: { id: number, body: OutboundPayload }): Promise<ApiResponse> => _callApi(`/outbounds/${id}`, "put", body);
export const deleteOutbound = (id: number) => _callApi(`/outbounds/${id}`, "delete");

/* TRANSFER APIs */
export const getTransfers = ({ offset, limit, fromWarehouse, toWarehouse, itemCode, itemName, itrNumber, startDate, endDate }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/transfers?offset=${offset ?? 0}&limit=${limit ?? 100}&fromWarehouse=${fromWarehouse || ""}&toWarehouse=${toWarehouse || ""}&
		itemCode=${itemCode || ""}&itemName=${itemName || ""}&startDate=${startDate || ""}&
		endDate=${endDate || ""}&itrNumber=${itrNumber || ""}`, 'get'
	);
export const getTransfer = (id: number): Promise<ApiSingleResponse> => _callApi(`/transfers/${id}`, "get");
export const addTransfer = (body: TransferPayLoad): Promise<ApiResponse> => _callApi(`/transfers`, "post", body);
export const updateTransfer = ({ id, body }: { id: number, body: TransferPayLoad }): Promise<ApiResponse> =>
	_callApi(`/transfers/${id}`, "put", body);
export const deleteTransfer = (id: number) => _callApi(`/transfers/${id}`, "delete");

/* AVG_DEMAND APIs */
export const getAverageDemands = ({ offset, limit, year, month }: QueryParams): Promise<ApiResponse> =>
	_callApi(`/demands?offset=${offset ?? 0}&limit=${limit ?? 100}&year=${year || ""}&month=${month || ""}`, 'get');
export const getAverageDemand = (id: number): Promise<ApiSingleResponse> => _callApi(`/demands/${id}`, "get");
export const addAverageDemand = (body: DemandPayload): Promise<ApiResponse> => _callApi(`/demands`, "post", body);
export const updateAverageDemand = (body: DemandPayload[]): Promise<ApiResponse> => _callApi(`/demands`, "put", body);
export const deleteAverageDemand = (id: number) => _callApi(`/demands/${id}`, "delete");

/* BULK UPLOAD */
export const bulkUpload = async (file: FormData, fileType: string) => {
	try {
		let url = `/uploads/bulk/${fileType}`;
		const response = await axios.post(url, file, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const { status, data } = response;
        if (status === 401) {
            return { success: false };
        } else if (status === 200 || status === 201) {
            return data;
        } else {
            return { success: false };
        }
	} catch (error) {
		const err = error as AxiosError;
		return err.response?.data;
	}
};

const _callApi = async (url: string, method: Methods = "get", body = {}) => {
    try {
        const response = await axios[method](url, body);
        const { status, data } = response;
        if (status === 401) {
            return { success: false };
        } else if (status === 200 || status === 201) {
            return data;
        } else {
            return { success: false };
        }
    } catch (error) {
        const err = error as AxiosError;
        return err.response?.data;
    }
};
