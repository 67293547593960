import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField,
	Box, Modal,
} from '@mui/material';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { queryConfigs } from '../../react-query/queryConfig';
import { useTheme } from '../../ThemeContext';
import { sanitizeValue } from '../../lib/helper';
import { useGetQuery } from '../../react-query/queryHook';
import { Inbound, Vendor, Warehouse, LoginResult } from '../../lib/models';
import { getVendors, getWarehouses, getExportURL } from '../../lib/api';
import Import from "../Import";

const userHeaderNames: string[] = [
    "Date", "Warehouse", "Item Code", "Parent Code",
    "Item Name", "Uom Group", "PO Quantity", "Quantity in Pieces",
    "Vendor Name", "PO Number",
];
const limit = 10;

export default function Inbounds({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getInbounds, queryKey: inboundKey } = queryConfigs.getInbounds;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemName");
    const [searchForm, setSearchForm] = useState({
        itemName: "", itemCode: "", warehouse: "",
        vendor: "", startDate: "", endDate: "",
        poNumber: "",
    });
    const navigate = useNavigate();
    const [inboundList, setInboundList] = useState<Inbound[]>([])
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])
    const [vendors, setVendors] = useState<Vendor[]>([])
	const [currentPage, setCurrentPage] = useState(1);
	const [openImport, setOpenImport] = useState(false)
    const { theme } = useTheme();

	const boxStyles = {
		position: 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: theme === 'dark' ? '#20293D' : 'white',
		color: theme === 'dark' ? 'white' : 'black',
		borderRadius: '10px',
		boxShadow: 24,
	};

    const { data: inbounds, refetch } = useGetQuery({
        key: inboundKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            itemName: searchForm?.itemName ?? "",
            itemCode: searchForm?.itemCode ?? "",
            warehouse: searchForm?.warehouse ?? "",
            vendor: searchForm?.vendor ?? "",
            startDate: searchForm?.startDate ?? "",
            endDate: searchForm?.endDate ?? "",
            poNumber: searchForm?.poNumber ?? "",
        },
        func: getInbounds,
    });

    useEffect(() => {
        fetchWarehouses();
        fetchVendors();
    }, []);

    const fetchWarehouses = async () => {
        try {
            const response = await getWarehouses({offset:0 , limit: 1000});
            if (response?.success) {
                setWarehouses (response?.result?.list as Warehouse [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
    
    const fetchVendors = async () => {
        try {
            const response = await getVendors({offset:0 , limit: 1000});
            if (response?.success) {
                setVendors (response?.result?.list as Vendor [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (inbounds) {
            const list = inbounds?.result?.list as Inbound[];
            setInboundList(list);
        }
    }, [inbounds]);

    const handleAddClick = () => {
        navigate('add-inbound');
    };


    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            itemName: searchType === "itemName" ? searchValue: "",
            itemCode: searchType === "itemCode" ? searchValue: "",
            warehouse: searchType === "warehouse" ? searchValue: "",
            vendor: searchType === "vendor" ? searchValue: "",
            startDate: searchType === "startDate" ? searchValue: "",
            endDate: searchType === "endDate" ? searchValue: "",
            poNumber: searchType === "poNumber" ? searchValue: "",
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemName");
		setSearchForm({
			itemName: "", itemCode: "", warehouse: "", vendor: "", poNumber: "",
			startDate: "", endDate: "",
		});
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

	const exportHandler = () => {
		let url: string = getExportURL("inbounds");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&itemName=${searchForm?.itemName ?? ""}&
			warehouse=${searchForm?.warehouse ?? ""}&vendor=${searchForm?.vendor ?? ""}&
			startDate=${searchForm?.startDate ?? ""}&endDate=${searchForm?.endDate}&
			poNumber=${searchForm?.poNumber ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(inbounds?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
                componentType='stockIn'
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="itemName" style={{ fontFamily: 'Poppins' }}>Item Name</MenuItem>
                                    <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                    <MenuItem value="poNumber" style={{ fontFamily: 'Poppins' }}>Po Number</MenuItem>
                                    <MenuItem value="warehouse" style={{ fontFamily: 'Poppins' }}>Warehouse</MenuItem>
                                    <MenuItem value="vendor" style={{ fontFamily: 'Poppins' }}>Vendor</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["itemName", "itemCode", "poNumber"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                            }}
                                            sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            }}
                                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"warehouse".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Warehouse</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {warehouses?.map((item: Warehouse) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {"vendor".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Vendor</h2>
                                    <FormControl sx={{ width: "50%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {vendors?.map((item: Vendor) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {inboundList?.map((inbound: Inbound) =>(
                    <TableRow key={inbound.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">
                        {new Date(inbound?.createdOn).toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric', }).replace(/(\s)(\d{4})$/, ', $2')}
                        </TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{inbound.warehouseCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.itemCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.parentCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600,width: '300px',}} className="capitalize text-gray-800 dark:text-white">{inbound.itemName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.uomGroup}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.poQuantity}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.pieces}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.vendorCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{inbound.poNumber}</TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={boxStyles} >
					<Import fileType="inbounds" refresh={refetch} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};


