import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	text: string;
	handleConfirm: () => void;
	handleCancel: () => void;
	theme: string;
};

export default function DeleteCard(props: Props) {
	return (
		<Dialog
                open={props.isOpen}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { backgroundColor: props.theme === 'dark' ? '#333533' : 'white'},
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        color: 'white',
                        fontSize: '20px',
                        textAlign: 'center',
                        backgroundColor: '#202829',
                    }}
                >
                    CONFIRM DELETE
                </DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 8
                    }}
                >
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                            color: props.theme === 'dark' ? 'white' : 'black',
                            fontSize: '20px',
                            textAlign: 'center',
                        }}
                    >
						{props.text}
                    </DialogContentText>
                </DialogContent>
                <div className='flex justify-center items-center'>
                    <DialogActions>
                        <button onClick={props.handleConfirm} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 text-sm ease-in-out group-hover:text-white">Yes</span>
                            <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                        </button>
                        <button onClick={props.handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 text-sm ease-in-out group-hover:text-white">No</span>
                            <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                        </button>
                    </DialogActions>
                </div>
            </Dialog>
	);
}
