import { useEffect, useState } from 'react';
import {
	TableCell, TableRow, Tooltip, TextField,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { Classification, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = ["Classification", "Target Days Cover", ""];
const limit = 10

export default function Classifications({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getClassifications, queryKey: classificationKey } = queryConfigs.getClassifications;
    const { mutationFn: deleteClassification } = queryConfigs.deleteClassification;
    const [searchValue, setSearchValue] = useState("");
    const [searchForm, setSearchForm] = useState({ classification: "" });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [classificationToDelete, setClassificationToDelete] = useState<number | null>(null);
    const [classificationList, setClassificationList] = useState<Classification[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Classification Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: classifications, refetch } = useGetQuery({
        key: classificationKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            classification: searchForm.classification ?? "",
        },
        func: getClassifications,
    });

    const mutateDelete = useMutationQuery({ key: classificationKey, func: deleteClassification, onSuccess });


    useEffect(() => {
        if (classifications) {
            const list = classifications?.result?.list as Classification[];
            setClassificationList(list);
        }
    }, [classifications]);

    const handleAddClick = () => {
        navigate('add-classification');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-classification/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setClassificationToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (classificationToDelete) {
            mutateDelete.mutate(classificationToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            classification: searchValue ?? ""
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchForm({ classification: "" });
	};

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(classifications?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				cred={cred}
                children1={
                    <>
                      <div className=" flex items-center space-x-5 w-full">
                        <TextField
                          variant="outlined"
                          placeholder='Enter Classification'
                          name="name"
                          value={searchValue}
                          onChange={handleNameChange}
                          size="small"
                          inputProps={{
                            className: 'dark:text-white text-black',
                            }}
                            sx={{
                            width: 300,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.5rem',
                                height: '44px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                            }}
                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                        />      
                        <button onClick={handleSearch}  className="search-btn">
                          Search
                        </button>
                        <button onClick={handleSearchClear}  className="clear-btn">
                          Clear
                        </button>
                      </div>
                    </>
                  }
            >
                {classificationList?.map((classification: Classification) =>(
                    <TableRow key={classification.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{classification.classification}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{classification.targetDaysCover}</TableCell>
                        <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
                            <div className='flex justify-center items-center space-x-2'>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
										<button className="edit-btn" onClick={() => handleEditClick(classification.id)}>
											<FaEdit />
										</button>
									</Tooltip>
								}
								{cred?.hasDelete === 1 &&
									<Tooltip title="Delete" arrow>
										<button className="del-btn" onClick={() => handleDeleteClick(classification.id)}>
											<MdDeleteForever />
										</button>
									</Tooltip>
								}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Classification?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};
