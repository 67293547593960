import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { WarehousePayload } from '../../lib/payload';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { queryConfigs } from '../../react-query/queryConfig';
import { getWarehouse } from '../../lib/api';
import { Warehouse } from '../../lib/models';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function EditWarehouse() {
    const { mutationFn, queryKey } = queryConfigs.updateWarehouse
    const [formData, setFormData] = useState< WarehousePayload | null >(null);
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };
    const params = useParams ();
    const onSuccess = () => {
            showNotification("success", "Product Category Updated");
            navigate(-1);
        };

    const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        if (params.id) {
            fetchWarehouse(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchWarehouse = async (id: number) => {
        try {
            const response = await getWarehouse(id);
            if (response?.success) {
                const result = response?.result as Warehouse;
                setFormData({
                    code: result.code,
                    description: result.description,
                    location: result.location
                });
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item Type  details");
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
            if (params.id && formData && validate(formData, "warehouse")) {
                updateMutation.mutate({id: params?.id, body: formData});
            }
            else {
                showNotification("error", "Missing Required Field");
            }
        };

    const handleCancel = () => {
        navigate(-1);
    };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Edit Warehouse
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Warehouse Code <span className='text-red-400'>*</span></h2>
            <TextField
                name="code"
                placeholder="Enter Warehouse Code"
                variant="outlined"
                value={formData?.code}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Description <span className='text-red-400'>*</span></h2>
            <TextField
                name="description"
                placeholder="Enter Description"
                variant="outlined"
                value={formData?.description}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Location <span className='text-red-400'>*</span></h2>
            <TextField
                name="location"
                placeholder="Enter Location"
                variant="outlined"
                value={formData?.location}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>

        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
        <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                    <span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
    </main>
  );
};
