import { useMutation, useQuery } from '@tanstack/react-query';
import { MutationProp, QueryProp, SingleQueryProp} from '@/lib/common';
import { toast } from "react-hot-toast";
import { useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from '../lib/models';

export function useGetQuery({ key, params, isEnabled, func }: QueryProp) {
    const offset = params?.offset || 0;
    const limit = params?.limit || 10;
    const queryParams = {
		...params,
		offset, limit
	};
	const keyArr: any[] = [...key];
	for (const [key, value] of Object.entries(queryParams)) {
		keyArr.push({ [key]: value });
	}
    return useQuery({
        queryKey: keyArr,
        queryFn: () => func(queryParams),
        enabled: isEnabled !== undefined ? isEnabled : true,
    });
};

export function useGetSingleQuery({ key, params, isEnabled, func }: SingleQueryProp) {
    const queryParams = {
		...params,
	};
	const keyArr: any[] = [...key];
	for (const [key, value] of Object.entries(queryParams)) {
		keyArr.push({ [key]: value });
	}
    return useQuery({
        queryKey: keyArr,
        queryFn: () => func(queryParams),
        enabled: isEnabled !== undefined ? isEnabled : true,
    });
};

export function useMutationQuery({ key, func, onSuccess }: MutationProp) {
	const client = useQueryClient();
    return useMutation({
        mutationFn: func,
        onSuccess: (data: ApiResponse) => {
            if(data.success) {
                onSuccess();
                client.invalidateQueries({ queryKey: key });
            } else {
                toast.error(data?.message || '');
            }
        }
    });
};
