import { useEffect, useState } from 'react';
import { CgMenuRightAlt, CgMenu } from "react-icons/cg";
import {
	Menu, MenuItem, IconButton, Tooltip, Collapse, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, ListItemIcon,
	FormControlLabel, Typography,
} from "@mui/material";
import {
	MdAccountCircle, MdDashboard, MdExpandLess, MdExpandMore,
	MdOutlineManageSearch, MdSettings, MdExitToApp, MdOutlineInventory,
} from "react-icons/md";
import { PiPackageFill } from "react-icons/pi";
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';
import DarkLogo from "../assets/logo-black.png";
import LightLogo from "../assets/logo-white.png";
import DarkLogoMin from "../assets/minlogo-black.png";
import LightLogoMin from "../assets/logo.png";
import Dashboard from '../component/dashboard/Dashboard';
import MaterialUISwitch from '../component/switch/MaterialUISwitch';
import AvgDemand from '../component/avgDemand/AvgDemand';
import Company from '../component/company/Company';
import EditCompany from '../component/company/EditCompany';
import AddBrand from '../component/brand/AddBrand';
import EditBrand from '../component/brand/EditBrand';
import AddItem from '../component/item/AddItem';
import EditItem from '../component/item/EditItem';
import AddClassification from '../component/classification/AddClassification';
import EditClassification from '../component/classification/EditClassification';
import EditWarehouse from '../component/warehouse/EditWarehouse';
import AddWarehouse from '../component/warehouse/AddWarehouse';
import Users from '../component/staff/User';
import AddUser from '../component/staff/AddUser';
import ItemTypes from '../component/itemType/ItemType';
import AddItemTypes from '../component/itemType/AddItemTypes';
import EditItemTypes from '../component/itemType/EditItemTypes';
import BrandCategories from '../component/brandCategory/BrandCategories';
import AddBrandCategories from '../component/brandCategory/AddBrandCategories';
import EditBrandCategories from '../component/brandCategory/EditBrandCategories';
import AddCompanies from '../component/company/AddCompany';
import ProductCategories from '../component/productCategory/ProductCategories';
import AddProductCategories from '../component/productCategory/AddProductCategories';
import EditProductCategories from '../component/productCategory/EditProductCategories';
import Brands from '../component/brand/Brand';
import Uoms from '../component/unit/Uom';
import AddUom from '../component/unit/AddUom';
import EditUom from '../component/unit/EditUom';
import Roles from '../component/role/Roles';
import Items from '../component/item/Item';
import Classifications from '../component/classification/Classification';
import Warehouses from '../component/warehouse/Warehouse';
import AddRole from '../component/role/AddRole';
import EditRole from '../component/role/EditRole';
import EditUser from '../component/staff/EditUser';
import SalesCategories from '../component/salesCategory/SalesCategory';
import AddSalesCategory from '../component/salesCategory/AddSalesCategory';
import EditSalesCategory from '../component/salesCategory/EditSalesCategory';
import Channels from '../component/channel/Channels';
import AddChannel from '../component/channel/AddChannel';
import EditChannel from '../component/channel/EditChannel';
import Customers from '../component/customer/Customer';
import AddCustomer from '../component/customer/AddCustomer';
import EditCustomer from '../component/customer/EditCustomer';
import Vendors from '../component/vendor/Vendors';
import AddVendor from '../component/vendor/AddVendor';
import Countries from '../component/country/Country';
import AddCountry from '../component/country/AddCountry';
import EditCountry from '../component/country/EditCountry';
import EditVendor from '../component/vendor/Editvendor';
import Inbounds from '../component/inbound/Inbound';
import AddInbound from '../component/inbound/AddInbound';
import EditInbound from '../component/inbound/EditInbound';
import OutBounds from '../component/outbound/Outbound';
import Transfers from '../component/transfer/Transfer';
import AddOutbound from '../component/outbound/AddOutbound';
import EditOutbound from '../component/outbound/EditOutbound';
import Size from '../component/size/Size';
import AddSize from '../component/size/AddSize';
import EditSize from '../component/size/EditSize';
import Currencies from '../component/currency/Currency';
import AddCurrency from '../component/currency/AddCurrency';
import EditCurrency from '../component/currency/EditCurrency';
import AddTransfer from '../component/transfer/AddTransfer';
import EditTransfer from '../component/transfer/EditTransfer';
import AddAvgDemand from '../component/avgDemand/AddAvgDemand';
import ViewItem from '../component/item/ViewItem';
import { ApiSingleResponse, LoginResult, User } from '../lib/models';
import { deleteAll } from '../lib/helper';
import { getProfile, logout, setAuthHeader } from '../lib/api';
import Profile from './Profile';
import EditAvgDemand from '../component/avgDemand/EditAvgDemand';
interface SubmenuItem {
    id: number;
    link: string;
    label: string;
    subLabel: string;
    viewEnabled: boolean;
}

interface LinkItem {
    id: number;
    link?: string;
    icon: JSX.Element;
    label: string;
    viewEnabled: boolean;
    submenu?: SubmenuItem[];
}

type Props = {
    cred: LoginResult | null;
    setCred: (value: LoginResult | null) => void;
};

export default function AdminPage({ cred, setCred }: Props){
    const { theme, setTheme } = useTheme();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [anchorProfileMenu, setAnchorProfileMenu] = useState<null | HTMLElement>(null);
    const [expandedSubmenu, setExpandedSubmenu] = useState<number | null>(null);
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
    const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(!cred || !cred?.token) {
            navigate("/");
        } else {
            setIsLoading(false);
        }
	}, [cred, navigate]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsNavbarTransparent(false);
            } else {
                setIsNavbarTransparent(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
		};
    }, []);


    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response: ApiSingleResponse = await getProfile();
                if (response.success) {
                    setUser(response.result as User);
                } 
            } catch (err) {
				console.error(err);
            }
        };
        fetchProfile();
    }, []);
    

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
          setTheme(storedTheme === 'dark' ? 'dark' : 'light');
        }
      }, [setTheme]);
    
      const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTheme = event.target.checked ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
      };

    const links: LinkItem[] = [
        {
            id: 1,
            link: "/admin",
            icon: <MdDashboard />,
            label: "Dashboard",
            viewEnabled: true
        },
        {
            id: 2,
            link: "/admin/*",
            icon: <MdOutlineManageSearch />,
            label: "Administration",
            viewEnabled: true,
            submenu: [
                {
                    id: 2.1,
                    link: "/admin/role",
                    label: "✾ Roles",
                    subLabel:"R",
                    viewEnabled: true
                },
                {
                    id: 2.2,
                    link: "/admin/user",
                    label: "✾ Users",
                    subLabel:"U",
                    viewEnabled: true
                },
                {
                    id: 2.3,
                    link: "/admin/company",
                    label: "✾ Companies",
                    subLabel:"C",
                    viewEnabled: true
                },
                {
                    id: 2.4,
                    link: "/admin/warehouse",
                    label: "✾ Warehouses",
                    subLabel:"W",
                    viewEnabled: true
                },
                {
                    id: 2.5,
                    link: "/admin/salesCategory",
                    label: "✾ Sales Categories",
                    subLabel:"SC",
                    viewEnabled: true
                },
                {
                    id: 2.6,
                    link: "/admin/channel",
                    label: "✾ Channels",
                    subLabel:"C",
                    viewEnabled: true
                },
                {
                    id: 2.7,
                    link: "/admin/customer",
                    label: "✾ Customers",
                    subLabel:"C",
                    viewEnabled: true
                },
                {
                    id: 2.8,
                    link: "/admin/country",
                    label: "✾ Countries",
                    subLabel:"C",
                    viewEnabled: true
                },
                {
                    id: 2.9,
                    link: "/admin/vendor",
                    label: "✾ Vendors",
                    subLabel:"V",
                    viewEnabled: true
                },
                {
                    id: 2.10,
                    link: "/admin/currency",
                    label: "✾ Currencies",
                    subLabel:"C",
                    viewEnabled: true
                },
            ],
        },
        {
            id: 3,
            link: "/admin/*",
            icon: <MdOutlineInventory />,
            label: "Inventory",
            viewEnabled: true,
            submenu: [
                { 
                    id: 3.1,
                    link: "/admin/itemType",
                    label: "✾ Item Types",
                    subLabel:"IT",
                    viewEnabled: true
                },
                { 
                    id: 3.2,
                    link: "/admin/categoryBrand",
                    label: "✾ Brand Categories",
                    subLabel:"BC",
                    viewEnabled: true
                },
                {
                    id: 3.3,
                    link: "/admin/productCategory",
                    label: "✾ Product Categories",
                    subLabel:"PC",
                    viewEnabled: true
                },
                {
                    id: 3.4,
                    link: "/admin/brand",
                    label: "✾ Brands",
                    subLabel:"B",
                    viewEnabled: true
                },
                {
                    id: 3.5,
                    link: "/admin/unit",
                    label: "✾ Units",
                    subLabel:"U",
                    viewEnabled: true
                },
                {
                    id: 3.6,
                    link: "/admin/classification",
                    label: "✾ Classifications",
                    subLabel:"CL",
                    viewEnabled: true
                },
                {
                    id: 3.7,
                    link: "/admin/size",
                    label: "✾ Size",
                    subLabel:"S",
                    viewEnabled: true
                },
                {
                    id: 3.8,
                    link: "/admin/item",
                    label: "✾ Item Master Data",
                    subLabel:"I",
                    viewEnabled: true
                },
                // {
                //     id: 3.10,
                //     link: "/admin/inventoryValue",
                //     label: "✾ Inventory Values",
                //     subLabel:"I V",
                //     viewEnabled: true
                // },
            ],
        },
        {
            id: 4,
            link: "/admin/*",
            icon: <PiPackageFill />,
            label: "Inventory Transactions",
            viewEnabled: true,
            submenu: [
                {
                    id: 4.1,
                    link: "/admin/inbound",
                    label: "✾ Inbounds",
                    subLabel:"I",
                    viewEnabled: true
                },
                {
                    id: 4.2,
                    link: "/admin/outbound",
                    label: "✾ Outbounds",
                    subLabel:"OB",
                    viewEnabled: true
                },
                {
                    id: 4.3,
                    link: "/admin/transfer",
                    label: "✾ Transfers",
                    subLabel:"T",
                    viewEnabled: true
                },
                // {
                //     id: 4.4,
                //     link: "/admin/stockReturn",
                //     label: "✾ Stocks Return",
                //     subLabel:"SR",
                //     viewEnabled: true
                // },
                // {
                //     id: 4.5,
                //     link: "/admin/stockAllocation",
                //     label: "✾ Stocks Allocation",
                //     subLabel:"SA",
                //     viewEnabled: true
                // },
            ],
        },
        {
            id: 5,
            link: "/admin/*",
            icon: <MdOutlineManageSearch />,
            label: "Item Reports",
            viewEnabled: true,
            submenu: [
                {
                    id: 5.1,
                    link: "/admin/avgDemand",
                    label: "✾ Average Demand",
                    subLabel:"S",
                    viewEnabled: true
                },
                // {
                //     id: 5.2,
                //     link: "/admin/staff",
                //     label: "✾ Aging Report",
                //     subLabel:"S",
                //     viewEnabled: true
                // },
            ],
        },
        // {
        //     id: 6,
        //     link: "/admin/demandPlaning",
        //     icon: <FaChartLine />,
        //     label: "Demand Planing",
        //     viewEnabled: true,
        //     submenu: [
        //         {
        //             id: 8.1,
        //             link: "/admin/forecast",
        //             label: "✾ Forecast Accuracy",
        //             subLabel:"FA",
        //             viewEnabled: true
        //         },
        //     ],
        // },
    ];

    useEffect(() => {
        const temp = JSON.parse(localStorage?.getItem('isSidebarCollapsed') || 'null');
        if (temp !== null) {
            setIsSidebarCollapsed(temp);
        }
    }, []);

    const toggleCollapsed = () => {
        localStorage.setItem('isSidebarCollapsed', JSON.stringify(!isSidebarCollapsed));
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorProfileMenu(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorProfileMenu(null);
    };

    const handleProfileClick = () => {
        navigate('/admin/profile');
        handleProfileMenuClose();
    };

    const handleLogoutClick = () => {
        setIsLogoutDialogOpen(true);
        handleProfileMenuClose();
    };

    const handleSubmenuToggle = (id: number) => {
        setExpandedSubmenu(expandedSubmenu === id ? null : id);
    };

    const handleLogoutConfirm = async () => {
        setIsLogoutDialogOpen(false);
        await logout();
        setCred(null);
        deleteAll();
        navigate('/');
		setAuthHeader(null);
    };

    const handleLogoutCancel = () => {
        setIsLogoutDialogOpen(false);
    };


    const logoSrc = theme === 'dark' ? LightLogo : DarkLogo;
    const logoSrcMin = theme === 'dark' ? LightLogoMin : DarkLogoMin;

    if(isLoading) return <center><h4>Loading...</h4></center>;
    return (
        <div className={`w-full min-h-screen mx-auto  bg-[#f7f8f9] dark:bg-[#141729] text-gray-900 dark:text-white relative p-4 ${theme === 'dark' ? 'dark' : ''}`}
        >
            {/* Fixed Navbar */}
            <div className={`${isSidebarCollapsed ? 'w-[calc(100%-130px)]' : 'w-[calc(100%-290px)]'} fixed top-[20px] right-[12px] rounded-xl z-50 ${isNavbarTransparent ? 'bg-transparent text-black dark:text-white' : 'bg-[#7c5082] text-white dark:bg-[#202829] dark:text-white'} backdrop-blur-sm drop-shadow-sm px-4 py-4 justify-between items-center transition-all duration-300 ease-in-out flex`}>
                <div className="flex items-center">
                    <button onClick={toggleCollapsed} className="mr-2">
                        {isSidebarCollapsed ? <CgMenu size={24} /> : <CgMenuRightAlt size={24} />}
                    </button>
                </div>
                <div className="flex items-center">
                <span className={`capitalize text-lg font-bold ${isNavbarTransparent ? 'text-[#7c5082]' : 'text-white'}`}>Welcome! <span className={`text-sm  ${isNavbarTransparent ? 'text-[#7c5082]' : 'text-white'}`}>{user?.name}</span></span>
                    {/* Profile */}
                    <Tooltip title='Settings'>
                        <IconButton aria-label="show profile" onClick={handleProfileMenuOpen}>
                            <MdSettings className={`w-6 ${isNavbarTransparent ? 'text-black' : 'text-white'} dark:text-white`} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorProfileMenu}
                        open={Boolean(anchorProfileMenu)}
                        onClose={handleProfileMenuClose}
                        slotProps={{
                            paper: {
                                style: {
                                    backgroundColor: theme === 'dark' ? '#202829' : 'white',
                                    color: theme === 'dark' ? 'white' : 'black',
                                },
                            },
                        }}
                    >
                        <MenuItem
                            sx={{
                                '&:hover': {
                                    color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
                                    backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
                                },
                            }}
                        >
                            <div className='flex justify-end items-center w-[90px] drop-shadow-xl rounded-3xl'>
                                <FormControlLabel
                                    control={<MaterialUISwitch checked={theme === 'dark'} onChange={handleThemeChange} />}
                                    label={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: '13px',
                                                fontWeight: '700',
                                            }}
                                        >
                                            {theme === 'dark' ? 'Dark' : 'Light'}
                                        </Typography>
                                    } 
                                    sx={{ m: 1 }}
                                />
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={handleProfileClick}
                            sx={{
                                '&:hover': {
                                    color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
                                    backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: theme === 'dark' ? 'white' : 'black',
                                    transition: 'color 0.3s',
                                    '&:hover': {
                                        color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
                                    },
                                }}
                            >
                                <MdAccountCircle />
                            </ListItemIcon>
                            <span className='font-Poppins font-semibold text-sm'>Profile</span>
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogoutClick}
                            sx={{
                                '&:hover': {
                                    color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
                                    backgroundColor: theme === 'dark' ? '#303030' : '#f0f0f0',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: theme === 'dark' ? 'white' : 'black',
                                    transition: 'color 0.3s',
                                    '&:hover': {
                                        color: theme === 'dark' ? '#bb7ac4' : '#7c5082',
                                    },
                                }}
                            >
                                <MdExitToApp />
                            </ListItemIcon>
                            <span className='font-Poppins font-semibold text-sm'>Logout</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            {/* Logout Confirmation Dialog */}
                <Dialog
                    open={isLogoutDialogOpen}
                    onClose={handleLogoutCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            backgroundColor: theme === 'dark' ? '#333533' : 'white',
                        },
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                            color: 'white',
                            fontSize: '20px',
                            textAlign: 'center',
                            backgroundColor: '#202829',
                        }}
                    >
                        {"Confirm Logout?"}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: 8
                        }}
                    >
                        <DialogContentText
                            id="alert-dialog-description"
                            style={{
                                fontFamily: 'Poppins, sans-serif',
                                color: theme === 'dark' ? 'white' : 'black',
                                fontSize: '20px',
                                textAlign: 'center',
                            }}
                        >
                            Are you sure you want to logout?
                        </DialogContentText>
                    </DialogContent>
                <div className='flex justify-center items-center'>
                <DialogActions>
                    <button onClick={handleLogoutConfirm} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                        <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#3d753f] opacity-[3%]"></span>
                        <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#3d753f] opacity-100 group-hover:-translate-x-8"></span>
                        <span className="relative w-full text-left text-[#3d753f] transition-colors duration-200 text-sm ease-in-out group-hover:text-white">Yes</span>
                        <span className="absolute inset-0 border-2 border-[#3d753f] rounded-lg"></span>
                    </button>
                        <button onClick={handleLogoutCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                            <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#6e3333] opacity-[3%]"></span>
                            <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#6e3333] opacity-100 group-hover:-translate-x-8"></span>
                            <span className="relative w-full text-left text-[#6e3333] transition-colors duration-200 text-sm ease-in-out group-hover:text-white">No</span>
                            <span className="absolute inset-0 border-2 border-[#6e3333] rounded-lg"></span>
                        </button>
                </DialogActions>
                </div>
            </Dialog>


            {/* Sidebar */}
            <div className={`${isSidebarCollapsed ? 'w-[90px]' : 'w-[250px]'} fixed h-[calc(100%-40px)] p-2 flex flex-col bg-transparent text-black dark:text-white rounded-xl top-[20px] z-10 transition-all duration-300 ease-in-out`}>
            {/* Logo or brand */}
                <div className="flex flex-col items-center border-b-[0.5px] border-gray-400 justify-center">
                <img src={logoSrc} alt="Logo" className={`${isSidebarCollapsed ? 'hidden' : 'w-[160px] h-[100px] py-2'}`} />
                <img src={logoSrcMin} alt="Logo" className={`${isSidebarCollapsed ? 'w-[50px] h-[40px] p-2' : 'hidden'}`} />
                </div>
                {/* Navigation links */}
                <ul className='flex flex-col px-2 my-6 overflow-y-auto'>
                    {links.map((link) => (
                        <div key={link.id}>
                            {link.submenu ? (
                                <div>
                                    {/* minimised sidebar */}
                                    <Tooltip title={link.label} placement="right" arrow>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSubmenuToggle(link.id);
                                            }}
                                            className={`
                                                ${isSidebarCollapsed ? 'mb-2 px-2 py-2 justify-center rounded-md cursor-pointer hover:bg-white' : ' px-6 mb-2 py-2 rounded-md hover:bg-white cursor-pointer'} 
                                                items-center transition-all duration-300 ease-in-out flex w-full hover:text-[#7c5082] hover:bg-white
                                                ${location.pathname.startsWith(link.link ?? '') ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
                                            `}
                                        >
                                            {link.icon}
                                            <span className={`${isSidebarCollapsed ? 'hidden' : 'ml-2'}  text-sm w-full hover:text-[#7c5082] font-extralight font-Poppins`}>
                                                {link.label}
                                            </span>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleSubmenuToggle(link.id);
                                                }}
                                            >
                                                {expandedSubmenu === link.id ? <MdExpandLess className='w-full text-xs text-black dark:text-white hover:text-black dark:hover:text-black' /> : <MdExpandMore className='w-full text-xs text-black dark:text-white hover:text-black dark:hover:text-black' />}
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Collapse in={expandedSubmenu === link.id} timeout="auto" unmountOnExit>
                                        <ul className={`${isSidebarCollapsed ? 'ml-2' : 'ml-6'}`}>
                                            {link.submenu.map((submenu) => (
                                                <li key={submenu.id} className="mb-2">
                                                    <Tooltip title={submenu.label} placement="right" arrow>
                                                        <NavLink
                                                            to={submenu.link}
                                                            className={`
                                                                 flex items-center w-full px-4 py-2 rounded-md hover:text-[#7c5082] hover:bg-white
                                                                transition-all duration-300 ease-in-out 
                                                                ${location.pathname === submenu.link ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
                                                            `}
                                                        >
                                                            <span className={`${isSidebarCollapsed ? ' text-xs font-extralight font-Poppins' : ' text-sm font-extralight font-Poppins'}`}>
                                                                {isSidebarCollapsed ? submenu.subLabel : submenu.label}
                                                            </span>
                                                        </NavLink>
                                                    </Tooltip>
                                                </li>
                                            ))}
                                        </ul>
                                    </Collapse>
                                </div>
                            ) : (
                                // expanded sidebar
                                <Tooltip title={link.label} placement="right" arrow>
                                    <NavLink
                                        to={link.link ?? "/"}
                                        className={`
                                            ${isSidebarCollapsed ? ' mb-2 px-2 py-2 rounded-md justify-center' : ' px-6 mb-2 py-2 rounded-md hover:bg-white'} 
                                            flex items-center w-full transition-all duration-300 ease-in-out  hover:text-[#7c5082]
                                            ${location.pathname === link.link ? 'bg-white dark:bg-[#7c5082] text-black dark:text-white drop-shadow-sm' : ''}
                                        `}
                                    >
                                        {link.icon}
                                        <span className={`${isSidebarCollapsed ? 'hidden' : 'ml-2'} text-sm font-extralight font-Poppins`}>
                                            {link.label}
                                        </span>
                                    </NavLink>
                                </Tooltip>
                            )}
                        </div>
                    ))}
                </ul>
            </div>
            {/* Main Content */}
            <main className={`flex-1 transition-all mt-4 duration-300 ease-in-out ${isSidebarCollapsed ? 'ml-[105px]' : 'ml-[275px]'}  ${!isNavbarTransparent && 'blur-background'}`}>
                <Routes>
                	<Route index element={<Dashboard pageTitle='Dashboard' isSidebarCollapsed={isSidebarCollapsed} />} />
                    <Route path="profile" element={<Profile />} />
                    {/* role */}
                    <Route path="role" element={<Roles pageTitle='Roles' cred={cred} />} />
                    <Route path="role/add-role" element={<AddRole />} />
                    <Route path="role/edit-role/:id" element={<EditRole />} />
                    {/* user */}
                    <Route path="user" element={<Users pageTitle='Users' cred={cred} />} />
                    <Route path="user/add-user" element={<AddUser />} />
                    <Route path="user/edit-user/:id" element={<EditUser />} />
                    {/* company */}
                    <Route path="company" element={<Company pageTitle='Companies' cred={cred} />} />
                    <Route path="company/add-company" element={<AddCompanies />} />
                    <Route path="company/edit-company/:id" element={<EditCompany />} />
                    {/* item-type */}
                    <Route path="itemType" element={<ItemTypes pageTitle='Item Types' cred={cred} />} />
                    <Route path="itemType/add-itemType" element={<AddItemTypes />} />
                    <Route path="itemType/edit-itemType/:id" element={<EditItemTypes />} />
                    {/* brandCategory */}
                    <Route path="categoryBrand" element={<BrandCategories pageTitle='Brand Categories' cred={cred} />} />
                    <Route path="categoryBrand/add-categoryBrand" element={<AddBrandCategories />} />
                    <Route path="categoryBrand/edit-categoryBrand/:id" element={<EditBrandCategories />} />
                    {/* productCategory */}
                    <Route path="productCategory" element={<ProductCategories pageTitle='Product Categories' cred={cred} />} />
                    <Route path="productCategory/add-productCategory" element={<AddProductCategories />} />
                    <Route path="productCategory/edit-productCategory/:id" element={<EditProductCategories />} />
                    {/* brand */}
                    <Route path="brand" element={<Brands pageTitle='Brands' cred={cred} />} />
                    <Route path="brand/add-brand" element={<AddBrand />} />
                    <Route path="brand/edit-brand/:id" element={<EditBrand />} />
                    {/* uom */}
                    <Route path="unit" element={<Uoms pageTitle='Units of Measurement' cred={cred} />} />
                    <Route path="unit/add-unit" element={<AddUom />} />
                    <Route path="unit/edit-unit/:id" element={<EditUom />} />
                    {/* item */}
                    <Route path="item" element={<Items pageTitle='Items' cred={cred} />} />
                    <Route path="item/add-item" element={<AddItem />} />
                    <Route path="item/edit-item/:id" element={<EditItem />} />
                    <Route path="item/view-item/:id" element={<ViewItem />} />
                    {/* classification */}
                    <Route path="classification" element={<Classifications pageTitle='Classifications' cred={cred} />} />
                    <Route path="classification/add-classification" element={<AddClassification />} />
                    <Route path="classification/edit-classification/:id" element={<EditClassification />} />
                    {/* warehouse */}
                    <Route path="warehouse" element={<Warehouses pageTitle='Warehouses' cred={cred} />} />
                    <Route path="warehouse/add-warehouse" element={<AddWarehouse />} />
                    <Route path="warehouse/edit-warehouse/:id" element={<EditWarehouse />} />
                    {/* sales-category */}
                    <Route path="salesCategory" element={<SalesCategories pageTitle='Sales Category' cred={cred} />} />
                    <Route path="salesCategory/add-salesCategory" element={<AddSalesCategory />} />
                    <Route path="salesCategory/edit-salesCategory/:id" element={<EditSalesCategory />} />
                    {/* channel */}
                    <Route path="channel" element={<Channels pageTitle='Channels' cred={cred} />} />
                    <Route path="channel/add-channel" element={<AddChannel />} />
                    <Route path="channel/edit-channel/:id" element={<EditChannel />} />
                    {/* customer */}
                    <Route path="customer" element={<Customers pageTitle='Customers' cred={cred} />} />
                    <Route path="customer/add-customer" element={<AddCustomer />} />
                    <Route path="customer/edit-customer/:id" element={<EditCustomer />} />
                    {/* vendor */}
                    <Route path="vendor" element={<Vendors pageTitle='Vendors' cred={cred} />} />
                    <Route path="vendor/add-vendor" element={<AddVendor />} />
                    <Route path="vendor/edit-vendor/:id" element={<EditVendor />} />
                    {/* country */}
                    <Route path="country" element={<Countries pageTitle='Countries' cred={cred} />} />
                    <Route path="country/add-country" element={<AddCountry />} />
                    <Route path="country/edit-country/:id" element={<EditCountry />} />
                    {/* inbound */}
                    <Route path="inbound" element={<Inbounds pageTitle='Inbound' cred={cred} />} />
                    <Route path="inbound/add-inbound" element={<AddInbound />} />
                    <Route path="inbound/edit-inbound/:id" element={<EditInbound />} />
                    {/* outbound */}
                    <Route path="outbound" element={<OutBounds pageTitle='OutBound' cred={cred} />} />
                    <Route path="outbound/add-outbound" element={<AddOutbound />} />
                    <Route path="outbound/edit-outbound/:id" element={<EditOutbound />} />
                    {/* size */}
                    <Route path="size" element={<Size pageTitle='Sizes' cred={cred} />} />
                    <Route path="size/add-size" element={<AddSize />} />
                    <Route path="size/edit-size/:id" element={<EditSize />} />
                    {/* currency */}
                    <Route path="currency" element={<Currencies pageTitle='Currencies' cred={cred} />} />
                    <Route path="currency/add-currency" element={<AddCurrency />} />
                    <Route path="currency/edit-currency/:id" element={<EditCurrency />} />
                    {/* transfer */}
                    <Route path="transfer" element={<Transfers pageTitle='Transfers' cred={cred} />} />
                    <Route path="transfer/add-transfer" element={<AddTransfer />} />
                    <Route path="transfer/edit-transfer/:id" element={<EditTransfer />} />
                    {/* demand */}
                    <Route path="avgDemand" element={<AvgDemand pageTitle='Average Demands' cred={cred} />} />
                    <Route path="avgDemand/add-avgDemand" element={<AddAvgDemand />} />
                    <Route path="avgDemand/edit-all-avgDemand" element={<EditAvgDemand />} />
                </Routes>
            </main>
        </div>
    );
};
