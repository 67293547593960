import { useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { queryConfigs } from '../../react-query/queryConfig';
import { showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { validate } from '../../lib/validation';
import { ItemTypePayload } from '@/lib/payload';
import { IoMdArrowBack } from "react-icons/io";

export default function AddItemTypes() {
  const { mutationFn, queryKey } = queryConfigs.addItemType
  const [formData, setFormData] = useState<ItemTypePayload>({
      name: '',
      });
  const navigate = useNavigate();
  const onSuccess = () => {
    showNotification("success", "Item Type Added");
    navigate(-1);
};

const addMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate(formData, "itemType")) {
        addMutation.mutate(formData);
    }
    else {
        showNotification("error", "Missing Required Field");
    }
};
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Add Item Type
			</span>
		</div>
        <form onSubmit={handleSubmit} className='flex py-6 justify-center items-center'>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Item Type Name <span className='text-red-400'>*</span></h2>
            <TextField
                name="name"
                placeholder="Enter Item Type Name"
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: 400,
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
                <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                    <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
    </main>
  );
};
