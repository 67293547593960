import React, { useState } from 'react';
import {
	Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Paper, Pagination, Stack,
  	IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '../ThemeContext';
import { RiAddFill } from 'react-icons/ri';
import { TbReload } from 'react-icons/tb';
import { MdArrowBack } from 'react-icons/md';
import { CiExport, CiImport } from "react-icons/ci";
import { FaEdit } from 'react-icons/fa';
import { LoginResult } from "../lib/models";

const TableCellStyled = styled(TableCell)(() => {
	const { theme: currentTheme } = useTheme();

	return {
		color: currentTheme === 'dark' ? '#cbd5e1' : '#a2a6b9',
		backgroundColor: currentTheme === 'dark' ? '#101422' : 'white',
	};
});

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  	color: theme.palette.mode === 'dark' ? '#cbd5e1' : '#a2a6b9',
}));

const TableHeader = styled(TableCell)({
	fontFamily: 'Poppins, sans-serif',
	fontWeight: 500,
	fontSize: '12px',
	backgroundColor: '#7c5082',
	color: 'white',
	position: 'sticky',
	top: 0,
	zIndex: 10,
	'&:hover': {
		color: 'white',
	},
});

const limit = 10;

interface CommonTableProps {
	tableHeaders: string[];
	children: React.ReactNode;
	children1?: React.ReactNode;
	children2?: React.ReactNode;
	totalRows: number;
	colSpan: number;
	noDataText?: string;
	pageTitle?: string;
	onAddClick?: () => void;
	onExportClick?: () => void;
	onImportClick?: () => void;
	onEditAllClick?: () => void;
	showBackBtn?: boolean;
	gobackFunc?: () => void;
	showReloadBtn?: boolean;
	reloadClick?: () => void;
	reload: () => void;
	componentType?: string;
	marginTop?: number;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	cred?: LoginResult | null;
}

export default function CommonTable({
	tableHeaders, children, children1, children2,onEditAllClick,
	totalRows, colSpan, noDataText = 'No Data Found',
	pageTitle, onAddClick, onExportClick, onImportClick, 
	showBackBtn = false, gobackFunc = () => {},
	showReloadBtn = true, reload,
	componentType, marginTop = 6,
	currentPage, setCurrentPage,
	cred = null,
}: CommonTableProps) {
	const { theme } = useTheme();
	const [isSpinning, setIsSpinning] = useState(false);
	const handleChangePage = (e: any, newPage: number) => {
		e.preventDefault();
		setCurrentPage(newPage);
	};

	const handleReload = () => {
		setIsSpinning(true);
		reload();
		setTimeout(() => setIsSpinning(false), 300);
	};

	const shortenLastWord = (title: string): string => {
		if (title.endsWith('ies')) {
			return title.slice(0, -3) + 'y';
		}
		const words = title.split(' ');
		if (words.length > 0) {
	  		const lastWord = words[words.length - 1];
	  		if (lastWord.endsWith('y')) {
				return title;
	  		}
	  		const shortenedLastWord = lastWord.slice(0, 0);
	  		words[words.length - 0] = shortenedLastWord;
		}
		return words.join(' ');
	};

	const shortenedTitle = shortenLastWord(pageTitle || '');

  	return (
    	<TableContainer
     		component={Paper}
      		sx={{
        		marginTop: marginTop,
        		height: '100%',
        		overflow: 'hidden',
      		}}
    	>
			<div className="flex flex-col">
				<div className="flex justify-between px-6 py-2 bg-white dark:bg-[#101422] sticky top-0 z-20">
					<div className="flex justify-center items-center space-x-2">
						<h1 className="text-2xl font-Poppins text-gray-700 dark:text-white font-semibold mb-4 mt-4">
							{pageTitle}
						</h1>
						<div className="flex space-x-1 items-center">
							{showBackBtn && (
								<IconButton onClick={gobackFunc}>
									<MdArrowBack className="text-lg text-gray-700 dark:text-white" />
								</IconButton>
							)}
							{showReloadBtn && (
								<IconButton onClick={handleReload}
									className={`flex items-center justify-center p-2 transition-all duration-300 ${
									isSpinning ? "spin" : ""
								}`}>
									<TbReload className={`text-lg text-gray-700 dark:text-white ${
										isSpinning ? "animate-spin" : ""
									}`} />
								</IconButton>
						  	)}
						</div>
			  		</div>
					<div className="flex gap-3">
						{cred?.hasAdd === 1 && onAddClick && (
							<button
							  className="add-modal-btn"
							  onClick={onAddClick}
							  aria-label={`Add ${shortenedTitle}`}
							>
							  <RiAddFill className="inline-block mr-2" /> 
							  <span className='font-Poppins text-sm'>Add {shortenedTitle}</span>
							</button>
						)}
						{cred?.hasEdit === 1 && onEditAllClick && ( // Add this section
							<button
								className="add-modal-btn"
								onClick={onEditAllClick}
							>
								<FaEdit className="inline-block mr-2" /> 
								<span className='font-Poppins text-sm'>Edit All</span>
							</button>
						)}
						{cred?.hasAdd === 1 && onImportClick && (
							<button
							  className="add-modal-btn"
							  onClick={onImportClick}
							>
								<CiImport className="inline-block mr-2" />
								<span className='font-Poppins text-sm'>Import</span>
							</button>
						)}
						{onExportClick && (
							<button
							  className="add-modal-btn"
							  onClick={onExportClick}
							>
								<CiExport className="inline-block mr-2" />
								<span className='font-Poppins text-sm'>Export</span>
							</button>
						)}
					</div>
				</div>
				<div className="flex flex-wrap gap-4 px-6 bg-white dark:bg-[#101422]">{children2}</div>
				<div className="flex flex-wrap gap-4 px-6 bg-white dark:bg-[#101422]">{children1}</div>
				<div className="flex justify-end px-8 py-4 bg-white dark:bg-[#101422]">
					<Stack spacing={2}>
						<Pagination
				  			count={Math.ceil(totalRows / limit)}
				  			page={currentPage}
				  			variant="outlined"
				  			color="primary"
				  			onChange={handleChangePage}
							sx={{
								'& .MuiPaginationItem-root': {
								  color: theme === 'dark' ? 'white' : '#9962a0',
								  borderColor: theme === 'dark' ? 'white' : '#9962a0',
								},
								'& .MuiPaginationItem-root.Mui-selected': {
								  backgroundColor: theme === 'dark' ? 'white' : '#9962a0',
								  color: theme === 'dark' ? 'black' : 'white',
								},
							}}
						/>
			  		</Stack>
			  		<div className="flex items-center ml-4 space-x-2 font-medium text-slate-700 dark:text-white">
						<p>Total Result:</p>
						<p>{totalRows}</p>
					</div>
				</div>
			</div>
			
			{/* Table */}
		  	<div className="overflow-auto max-h-[500px]">
				<Table stickyHeader sx={{ width: componentType === 'item' ? '3000px' 
					: componentType === 'stockIn' ? '1650px' 
					: componentType === 'stockOut' ? '1650px' 
					: componentType === 'transfer' ? '1650px' 
					: componentType === 'dashboard' ? '1650px' 
					: '100%' }} size="small"
				>
					<TableHead>
						<TableRowStyled>
							{tableHeaders.map((head) => (
								<TableHeader
									align="center"
									key={head}
									className="uppercase"
								>
									{head}
								</TableHeader>
							))}
						</TableRowStyled>
					</TableHead>
					<TableBody>
						{children}
						{totalRows === 0 && (
							<TableRowStyled>
								<TableCellStyled align="center" colSpan={colSpan}>
									<p className="text-xl font-Poppins py-4">{noDataText}</p>
								</TableCellStyled>
							</TableRowStyled>
						)}
					</TableBody>
				</Table>
		  	</div>
		</TableContainer>
	);
}
