import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DemandPayload, DemandBodyPayload } from '../../lib/payload';
import { queryConfigs } from '../../react-query/queryConfig';
import { Item } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { getItems } from '../../lib/api';
import { validate } from '../../lib/validation';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { IoMdArrowBack } from "react-icons/io";

export default function AddAvgDemand() {
  const { mutationFn, queryKey } = queryConfigs.addAverageDemand;
  const [formData, setFormData] = useState<DemandPayload[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const navigate = useNavigate();

  const onSuccess = () => {
    showNotification("success", "Average Demand Added");
    navigate(-1);
  };

  const addMutation = useMutationQuery({ key: queryKey, func: mutationFn, onSuccess });

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await getItems({ offset: 0, limit: 1000 });
      if (response?.success) {
        const fetchedItems = response?.result?.list as Item[] ?? [];
        setItems(fetchedItems);
        const initialFormData = fetchedItems.map(item => ({
          brand: Number(item.brand),
          item: item.id,
          month: selectedMonth ? selectedMonth.month() + 1 : 0,
          year: selectedYear,
          firstMonth: 0,
          secondMonth: 0,
          thirdMonth: 0,
        }));
        setFormData(initialFormData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (index: number, e: any) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    for (const data of formData) {
      if (!validate(data, "demand")) {
        showNotification("error", "Missing Required Field");
        return;
      }
      data.brand = sanitizeValue(data.brand);
      data.item = sanitizeValue(data.item);
      data.month = sanitizeValue(selectedMonth?.month()) + 1;
      data.year = sanitizeValue(selectedYear);
      data.firstMonth = sanitizeValue(data.firstMonth);
      data.secondMonth = sanitizeValue(data.secondMonth);
      data.thirdMonth = sanitizeValue(data.thirdMonth);
    }
    const form: DemandBodyPayload = {
      demands: formData,
    }
    addMutation.mutate(form);
  };

  const handleCancel = () => {
    navigate(-1);
  };
	
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
				<div className="flex flex-start">
					<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
					<span className="text-left text-2xl font-Poppins font-semibold ml-5">
						Add Average Demand
					</span>
				</div>

        {/* Month and Year Selection */}
        <div className="flex w-full space-x-4 mb-6 mt-4 px-12">
          <div className="flex flex-col w-full">
            <h2 className='font-Poppins'>Select Year <span className='text-red-400'>*</span></h2>
            <DatePicker
              views={['year']}
              value={dayjs(`${selectedYear}-01-01`)}
              onChange={(newValue) => {
                setSelectedYear(newValue?.year() || selectedYear);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: {
                    height: '40px',
                    '& input': {
                      height: '40px',
                      color: 'gray',
                    },
                    '& .MuiInputBase-input': {
                      padding: '0 12px',
                    },
                    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                    color: 'gray'
                    },
                  },
                },
              }}
              className="bg-slate-100 dark:bg-[#202829] w-[100px] rounded-lg"
            />
          </div>
          <div className="flex flex-col w-full">
            <h2 className='font-Poppins'>Select Month <span className='text-red-400'>*</span></h2>
            <DatePicker
              views={['month']}
              value={selectedMonth}
              onChange={(newValue) => setSelectedMonth(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: {
                    height: '40px',
                    '& input': {
                      height: '40px',
                      color: 'gray',
                    },
                    '& .MuiInputBase-input': {
                      padding: '0 12px',
                    },
                    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                        color: 'gray'
                    },
                  },
                },
              }}
              className="bg-slate-100 dark:bg-[#202829] w-[100px] rounded-lg"
            />
          </div>
        </div>
        
        {selectedMonth && (
          <form onSubmit={handleSubmit} className="py-6">
            <table className="min-w-full table-auto border-separate border-spacing">
              <thead>
                <tr>
                  <th className="p-2 text-sm font-Poppins text-left">Brand Code</th>
                  <th className="p-2 text-sm font-Poppins text-left">Parent Code</th>
                  <th className="p-2 text-sm font-Poppins text-left">Item Name</th>
                  <th className="p-2 text-sm font-Poppins text-left">
                    First Month ({selectedMonth.format('MMMM')})
                  </th>
                  <th className="p-2 text-sm font-Poppins text-left">
                    Second Month ({selectedMonth.add(1, 'month').format('MMMM')})
                  </th>
                  <th className="p-2 text-sm font-Poppins text-left">
                    Third Month ({selectedMonth.add(2, 'month').format('MMMM')})
                  </th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item: Item, index) => (
                  <tr key={item.id} className="text-left">
                    <td className="p-2 text-xs font-Poppins text-left">{item.brandCode}</td>
                    <td className="p-2 text-xs font-Poppins text-left">{item.parentCode}</td>
                    <td className="p-2 text-xs font-Poppins text-left">{item.itemName}</td>
                    <td className="p-2">
                      <TextField
                        name="firstMonth"
                        placeholder={`First Month (${selectedMonth.format('MMMM')})`}
                        variant="outlined"
                        value={formData[index]?.firstMonth === 0 ? '' : formData[index]?.firstMonth}
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          className: 'dark:text-white text-black',
                        }}
                        className="bg-slate-100 dark:bg-[#202829] w-[100px] rounded-lg"
                      />
                    </td>
                    <td className="p-2">
                      <TextField
                        name="secondMonth"
                        placeholder={`Second Month (${selectedMonth.add(1, 'month').format('MMMM')})`}
                        variant="outlined"
                        value={formData[index]?.secondMonth === 0 ? '' : formData[index]?.secondMonth}
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          className: 'dark:text-white text-black',
                        }}
                        className="bg-slate-100 dark:bg-[#202829] w-[100px] rounded-lg"
                      />
                    </td>
                    <td className="p-2">
                      <TextField
                        name="thirdMonth"
                        placeholder={`Third Month (${selectedMonth.add(2, 'month').format('MMMM')})`}
                        variant="outlined"
                        value={formData[index]?.thirdMonth === 0 ? '' : formData[index]?.thirdMonth}
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          className: 'dark:text-white text-black',
                        }}
                        className="bg-slate-100 dark:bg-[#202829] w-[100px] rounded-lg"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        )}
        {selectedMonth && (
            <div className='flex justify-center mt-8 space-x-12 items-center'>
                <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                    <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
        )}
      </main>
    </LocalizationProvider>
  );
}
