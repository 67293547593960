import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { queryConfigs } from '../../react-query/queryConfig';
import { validate } from '../../lib/validation';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { Uom } from '../../lib/models';
import { getUom } from '../../lib/api';
import { useMutationQuery } from '../../react-query/queryHook';
import { UomPayload } from '../../lib/payload';
import { IoMdArrowBack } from "react-icons/io";

export default function EditUom () {
    const { mutationFn, queryKey } = queryConfigs.updateUom
    const [formData, setFormData] = useState<UomPayload | null>(null);
    const navigate = useNavigate();
    const params = useParams ();
    const onSuccess = () => {
    	showNotification("success", "Unit of measurement Updated");
        navigate(-1);
    };
    const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        if (params.id) {
            fetchUom(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchUom = async (id: number) => {
        try {
            const response = await getUom(id);
            if (response?.success) {
                const result = response?.result as Uom;
                setFormData({
                    uomGroup: result.uomGroup,
                    multiplier: result.multiplier,
                });
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item Type  details");
        }
    };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
        if (params.id && formData && validate(formData, "uom")) {
            formData.multiplier = sanitizeValue(formData.multiplier);
            updateMutation.mutate({id: params?.id, body: formData});
        }
        else {
            showNotification("error", "Missing Required Field");
        }
    };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Edit Unit
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">UOM Group <span className='text-red-400'>*</span></h2>
            <TextField
                name="uomGroup"
                placeholder="Enter UOM Group Name"
                variant="outlined"
                value={formData?.uomGroup}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Multiplier <span className='text-red-400'>*</span></h2>
            <TextField
                name="multiplier"
                placeholder="Enter Multiplier"
                variant="outlined"
                value={formData?.multiplier}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
            <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                <span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
            </button>
            <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
        </div>
    </main>
  );
};
