import {
    getCompanies, addCompany,deleteCompany, updateCompany,
    getUsers, addUser, updateUser, deleteUser,
    getItemTypes, addItemType, updateItemType, deleteItemType,
    getBrandCategories, addBrandCategory, updateBrandCategory, deleteBrandCategory,
    getProductCategories, addProductCategory, updateProductCategory, deleteProductCategory,
    getBrands, addBrand, updateBrand, deleteBrand,
    getUoms, addUom, updateUom, deleteUom,
    getRoles, addRole, updateRole, deleteRole,
    getItems, addItem, updateItem, deleteItem,
    getClassifications, addClassification, updateClassification, deleteClassification,
    getWarehouses, addWarehouse, updateWarehouse, deleteWarehouse,
    getSalesCategories, addSalesCategory, updateSalesCategory, deleteSalesCategory,
    getChannels, addChannel, updateChannel, deleteChannel,
    getCustomers, addCustomer, updateCustomer, deleteCustomer,
    getVendors, addVendor, updateVendor, deleteVendor,
    getCountries, addCountry, updateCountry, deleteCountry,
    getInbounds, addInbound, updateInbound, deleteInbound,
    getOutbounds, addOutbound, updateOutbound, deleteOutbound,
    getSizes, addSize, updateSize, deleteSize,
    getCurrencies, addCurrency, updateCurrency, deleteCurrency,
    getTransfers, addTransfer, updateTransfer, deleteTransfer,
    getAverageDemands, addAverageDemand, updateAverageDemand, deleteAverageDemand,
    updatePassword, getDashboards,
   
 } from "../lib/api";
 import { queryKeys } from "./keys";

 export const queryConfigs = {
    // DASHBOARD
   //  getDashboard: {queryFn: getDashboard, queryKey: [queryKeys.dashboards]},
    getDashboards: {queryFn: getDashboards, queryKey: [queryKeys.dashboards]},

    // PASSWORD
    updatePassword: {mutationFn: updatePassword, queryKey: [queryKeys.passwords]},

    // COMPANY
    getCompanies: {queryFn: getCompanies, queryKey: [queryKeys.companies]},
    addCompany: {mutationFn: addCompany, queryKey: [queryKeys.companies]},
    updateCompany: {mutationFn: updateCompany, queryKey: [queryKeys.companies]},
    deleteCompany: {mutationFn: deleteCompany, queryKey: [queryKeys.companies]},

   //  ROLE
   getRoles: {queryFn: getRoles, queryKey: [queryKeys.roles]},
   addRole: {mutationFn: addRole, queryKey: [queryKeys.roles]},
   updateRole: {mutationFn: updateRole, queryKey: [queryKeys.roles]},
   deleteRole: {mutationFn: deleteRole, queryKey: [queryKeys.roles]},

   //  USER
   getUsers: {queryFn: getUsers, queryKey: [queryKeys.users]},
   addUser: {mutationFn: addUser, queryKey: [queryKeys.users]},
   updateUser: {mutationFn: updateUser, queryKey: [queryKeys.users]},
   deleteUser: {mutationFn: deleteUser, queryKey: [queryKeys.users]},

   //  ITEMTYPE
   getItemTypes: {queryFn: getItemTypes, queryKey: [queryKeys.itemTypes]},
   addItemType: {mutationFn: addItemType, queryKey: [queryKeys.itemTypes]},
   updateItemType: {mutationFn: updateItemType, queryKey: [queryKeys.itemTypes]},
   deleteItemType: {mutationFn: deleteItemType, queryKey: [queryKeys.itemTypes]},

   //  CUSTOMER
   getCustomers: {queryFn: getCustomers, queryKey: [queryKeys.customers]},
   addCustomer: {mutationFn: addCustomer, queryKey: [queryKeys.customers]},
   updateCustomer: {mutationFn: updateCustomer, queryKey: [queryKeys.customers]},
   deleteCustomer: {mutationFn: deleteCustomer, queryKey: [queryKeys.customers]},

   //  VENDOR
   getVendors: {queryFn: getVendors, queryKey: [queryKeys.vendors]},
   addVendor: {mutationFn: addVendor, queryKey: [queryKeys.vendors]},
   updateVendor: {mutationFn: updateVendor, queryKey: [queryKeys.vendors]},
   deleteVendor: {mutationFn: deleteVendor, queryKey: [queryKeys.vendors]},

   //  COUNTRY
   getCountries: {queryFn: getCountries, queryKey: [queryKeys.countries]},
   addCountry: {mutationFn: addCountry, queryKey: [queryKeys.countries]},
   updateCountry: {mutationFn: updateCountry, queryKey: [queryKeys.countries]},
   deleteCountry: {mutationFn: deleteCountry, queryKey: [queryKeys.countries]},

   //  BRANDCATEGORY
   getBrandCategories: {queryFn: getBrandCategories, queryKey: [queryKeys.brandCategories]},
   addBrandCategory: {mutationFn: addBrandCategory, queryKey: [queryKeys.brandCategories]},
   updateBrandCategory: {mutationFn: updateBrandCategory, queryKey: [queryKeys.brandCategories]},
   deleteBrandCategory: {mutationFn: deleteBrandCategory, queryKey: [queryKeys.brandCategories]},

   //  SALECATEGORY
   getSalesCategories: {queryFn: getSalesCategories, queryKey: [queryKeys.salesCategories]},
   addSalesCategory: {mutationFn: addSalesCategory, queryKey: [queryKeys.salesCategories]},
   updateSalesCategory: {mutationFn: updateSalesCategory, queryKey: [queryKeys.salesCategories]},
   deleteSalesCategory: {mutationFn: deleteSalesCategory, queryKey: [queryKeys.salesCategories]},

   //  CHANNEL
   getChannels: {queryFn: getChannels, queryKey: [queryKeys.channels]},
   addChannel: {mutationFn: addChannel, queryKey: [queryKeys.channels]},
   updateChannel: {mutationFn: updateChannel, queryKey: [queryKeys.channels]},
   deleteChannel: {mutationFn: deleteChannel, queryKey: [queryKeys.channels]},

   //  INBOUND
   getInbounds: {queryFn: getInbounds, queryKey: [queryKeys.inbounds]},
   addInbound: {mutationFn: addInbound, queryKey: [queryKeys.inbounds]},
   updateInbound: {mutationFn: updateInbound, queryKey: [queryKeys.inbounds]},
   deleteInbound: {mutationFn: deleteInbound, queryKey: [queryKeys.inbounds]},

   //  OUTBOUND
   getOutbounds: {queryFn: getOutbounds, queryKey: [queryKeys.outbounds]},
   addOutbound: {mutationFn: addOutbound, queryKey: [queryKeys.outbounds]},
   updateOutbound: {mutationFn: updateOutbound, queryKey: [queryKeys.outbounds]},
   deleteOutbound: {mutationFn: deleteOutbound, queryKey: [queryKeys.outbounds]},

   //  SIZES
   getSizes: {queryFn: getSizes, queryKey: [queryKeys.sizes]},
   addSize: {mutationFn: addSize, queryKey: [queryKeys.sizes]},
   updateSize: {mutationFn: updateSize, queryKey: [queryKeys.sizes]},
   deleteSize: {mutationFn: deleteSize, queryKey: [queryKeys.sizes]},

   //  PRODUCTCATEGORY
   getProductCategories: {queryFn: getProductCategories, queryKey: [queryKeys.productCategories]},
   addProductCategory: {mutationFn: addProductCategory, queryKey: [queryKeys.productCategories]},
   updateProductCategory: {mutationFn: updateProductCategory, queryKey: [queryKeys.productCategories]},
   deleteProductCategory: {mutationFn: deleteProductCategory, queryKey: [queryKeys.productCategories]},

   //  BRAND
   getBrands: {queryFn: getBrands, queryKey: [queryKeys.brands]},
   addBrand: {mutationFn: addBrand, queryKey: [queryKeys.brands]},
   updateBrand: {mutationFn: updateBrand, queryKey: [queryKeys.brands]},
   deleteBrand: {mutationFn: deleteBrand, queryKey: [queryKeys.brands]},

   //  CURRENCY
   getCurrencies: {queryFn: getCurrencies, queryKey: [queryKeys.currencies]},
   addCurrency: {mutationFn: addCurrency, queryKey: [queryKeys.currencies]},
   updateCurrency: {mutationFn: updateCurrency, queryKey: [queryKeys.currencies]},
   deleteCurrency: {mutationFn: deleteCurrency, queryKey: [queryKeys.currencies]},

   //  UOM
   getUoms: {queryFn: getUoms, queryKey: [queryKeys.uoms]},
   addUom: {mutationFn: addUom, queryKey: [queryKeys.uoms]},
   updateUom: {mutationFn: updateUom, queryKey: [queryKeys.uoms]},
   deleteUom: {mutationFn: deleteUom, queryKey: [queryKeys.uoms]},

   //  ITEM
   getItems: {queryFn: getItems, queryKey: [queryKeys.items]},
   addItem: {mutationFn: addItem, queryKey: [queryKeys.items]},
   updateItem: {mutationFn: updateItem, queryKey: [queryKeys.items]},
   deleteItem: {mutationFn: deleteItem, queryKey: [queryKeys.items]},

   //  CLASSIFICATION
   getClassifications: {queryFn: getClassifications, queryKey: [queryKeys.classifications]},
   addClassification: {mutationFn: addClassification, queryKey: [queryKeys.classifications]},
   updateClassification: {mutationFn: updateClassification, queryKey: [queryKeys.classifications]},
   deleteClassification: {mutationFn: deleteClassification, queryKey: [queryKeys.classifications]},

   //  WAREHOUSE
   getWarehouses: {queryFn: getWarehouses, queryKey: [queryKeys.warehouses]},
   addWarehouse: {mutationFn: addWarehouse, queryKey: [queryKeys.warehouses]},
   updateWarehouse: {mutationFn: updateWarehouse, queryKey: [queryKeys.warehouses]},
   deleteWarehouse: {mutationFn: deleteWarehouse, queryKey: [queryKeys.warehouses]},

   //  TRANSFER
   getTransfers: {queryFn: getTransfers, queryKey: [queryKeys.transfers]},
   addTransfer: {mutationFn: addTransfer, queryKey: [queryKeys.transfers]},
   updateTransfer: {mutationFn: updateTransfer, queryKey: [queryKeys.transfers]},
   deleteTransfer: {mutationFn: deleteTransfer, queryKey: [queryKeys.transfers]},

   //  DEMANDS
   getAverageDemands: {queryFn: getAverageDemands, queryKey: [queryKeys.demands]},
   addAverageDemand: {mutationFn: addAverageDemand, queryKey: [queryKeys.demands]},
   updateAverageDemand: {mutationFn: updateAverageDemand, queryKey: [queryKeys.demands]},
   deleteAverageDemand: {mutationFn: deleteAverageDemand, queryKey: [queryKeys.demands]},
 }
