import { useEffect, useState } from "react";
import { Route, Routes, Outlet } from 'react-router-dom';
import ScrollToTop from './component/ScrollOnTop';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import { LoginResult } from "./lib/models";
import { getItem } from "./lib/helper";
import { setAuthHeader } from "./lib/api";

function App() {
  const [cred, setCred] = useState<LoginResult | null>(null);

  useEffect(() => {
		const data = getItem("credentials");
		if(data) {
			const credData = JSON.parse(data)
			setCred(credData);
			setAuthHeader(credData.token);
		}
	}, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<LoginPage cred={cred} setCred={setCred} />} />
            <Route path="/admin/*" element={<AdminPage cred={cred} setCred={setCred} />} />
          </Route>
      </Routes>
    </>
  );
}

export default App;
