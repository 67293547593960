import { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { queryConfigs } from '../../react-query/queryConfig';
import { ItemPayload, BundleItemPayload } from '../../lib/payload';
import {
	Brand, BrandCategory, Classification, Currency, Item,
	ItemType, ProductCategory, Size, Uom, BundleItem,
} from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import {
	getBrandCategories, getBrands, getClassifications, getCurrencies, getItem,
	getItemTypes, getProductCategories, getSizes, getUoms, getBundleItems,
	getItems, deleteBundleItem,
} from '../../lib/api';
import { validate } from '../../lib/validation';
import { MdDeleteForever } from 'react-icons/md';
import { IoMdArrowBack } from "react-icons/io";

export default function EditItem() {
    const { mutationFn, queryKey } = queryConfigs.updateItem
    const [formData, setFormData] = useState<ItemPayload | null>(null);
    const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
    const [brandCategories, setBrandCategories] = useState<BrandCategory[]>([]);
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [uoms, setUoms] = useState<Uom[]>([]);
    const [classifications, setClassifications ] = useState<Classification[]>([]);
    const [sizes, setSizes] = useState<Size[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
	const [items, setItems] = useState<Item[]>([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams ();
    const onSuccess = () => {
    	showNotification("success", "Item Updated");
        navigate(-1);
    };
    const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});
	const selectStyle = {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiSelect-select': {
			height: 'auto',
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiSelect-icon': {
			color: theme.palette.text.primary,
		},
		'& .MuiOutlinedInput-input': {
			padding: '10px 14px',
		},
	};

	const inputStyle = {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: '44px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	};

    useEffect(() => {
		if (params.id) {
			console.log(params.id)
			fetchItem(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchItem = async (id: number) => {
        try {
            const response = await getItem(id);
            if (response?.success) {
				const result = response?.result as Item;
				const bundleItems: BundleItemPayload[] = [];
				if (result?.itemCategory?.toUpperCase() === "BUNDLE") {
					const bundleResponse = await getBundleItems(id);
					if (bundleResponse?.success) {
						const list = bundleResponse?.result?.list as BundleItem[] ?? [];
						for (const item of list) {
							bundleItems.push({
    							packItemId: item.packItemId,
								itemId: item.itemId,
								packItemCode: item.packItemCode,
								itemCode: item.itemCode,
								quantity: item.quantity,
							});
						}
					}
				}
				setFormData({
                    itemType: result.itemType,
                    brandCategory: result.brandCategory,
                    productCategory: result.productCategory,
                    brand: result.brand,
                    parentCode: result.parentCode,
                    itemCode: result.itemCode,
                    itemName: result.itemName,
                    classification: result.classification,
                    size:result.size,
                    uom: result.uom,
                    purchasePrice: result.purchasePrice,
                    currency: result.currency,
                    suggestedRetailPrice: result.suggestedRetailPrice,
					itemCategory: result.itemCategory,
					items: bundleItems,
				});
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item details");
        }
    };
  
    useEffect(() => {
        fetchItemTypes();
        fetchBrandCategories();
        fetchProductCategories();
        fetchBrands();
        fetchUoms();
        fetchClassifications();
        fetchSizes();
		fetchCurrencies();
		fetchItems();
    }, []);
  
    const fetchItemTypes = async () => {
        try {
            const response = await getItemTypes({offset:0 , limit: 1000});
            if (response?.success) {
                setItemTypes (response?.result?.list as ItemType [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
  
    const fetchBrandCategories = async () => {
        try {
            const response = await getBrandCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setBrandCategories (response?.result?.list as BrandCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
  
    const fetchProductCategories = async () => {
        try {
            const response = await getProductCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setProductCategories (response?.result?.list as ProductCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
  
    const fetchBrands = async () => {
        try {
            const response = await getBrands({offset:0 , limit: 1000});
            if (response?.success) {
                setBrands (response?.result?.list as Brand [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
  
    const fetchUoms = async () => {
        try {
            const response = await getUoms({offset:0 , limit: 1000});
            if (response?.success) {
                setUoms (response?.result?.list as Uom [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
    
  
    const fetchClassifications = async () => {
        try {
            const response = await getClassifications({offset:0 , limit: 1000});
            if (response?.success) {
                setClassifications (response?.result?.list as Classification [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
    
  
    const fetchSizes = async () => {
        try {
            const response = await getSizes({offset:0 , limit: 1000});
            if (response?.success) {
                setSizes (response?.result?.list as Size [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchCurrencies = async () => {
        try {
            const response = await getCurrencies({offset: 0 , limit: 1000});
            if (response?.success) {
                setCurrencies (response?.result?.list as Currency [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
	};

	const fetchItems = async () => {
		try {
			const response = await getItems({ offset: 0, limit: 1000 });
			if (response?.success) {
				setItems(response?.result?.list as Item[] ?? []);
			}
		} catch (err) {
			console.error(err);
		}
	};
  
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        	...formData,
        	[name]: value,
        });
    };

    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        	...formData,
        	[name as string]: value as string,
        });
	};

	const handleAddItem = () => {
		setFormData(prev => ({
			...formData,
			items: [...(prev?.items ?? []), { itemCode: "", quantity: 0 }],
		}));
	};

	const handleItemChange = (index: number, e: any) => {
		const { name, value } = e.target;
		const itemList = [...(formData?.items ?? [])];
		if (name === "itemData") {
			const itemDataSplit = value?.split("_");
			const itemId: number = sanitizeValue(itemDataSplit[0]);
			const itemCode: string = itemDataSplit[1];
			itemList[index] = {
				...itemList[index],
				itemId, itemCode,
				packItemCode: formData?.itemCode,
			};
		} else {
            itemList[index] = {
                ...itemList[index],
                quantity: sanitizeValue(value),
            }
        }
        setFormData({
            ...formData,
            items: itemList,
        });
	};
  
    const handleSubmit = (e: any) => {
        e.preventDefault();
		if (params.id && formData && validate(formData, "item")) {
			formData.itemType = sanitizeValue(formData.itemType);
			formData.brandCategory = sanitizeValue(formData.brandCategory);
			formData.brand = sanitizeValue(formData.brand);
			formData.uom = sanitizeValue(formData.uom);
			formData.classification = sanitizeValue(formData.classification);
			formData.size = sanitizeValue(formData.size);
			formData.currency = sanitizeValue(formData.currency);
			formData.purchasePrice = sanitizeValue(formData.purchasePrice);
			formData.suggestedRetailPrice = sanitizeValue(formData.suggestedRetailPrice);
			updateMutation.mutate({id: params?.id, body: formData});
		}
		else {
			showNotification("error", "Missing Required Field");
		}
	};

	const handleRemoveItem = async (itemId: number, index: number) => {
		const data: ItemPayload = {...formData};
		let items: BundleItemPayload[] = [...data?.items ?? []];
		if (itemId === 0) {
			items.splice(index, 1);
			data.items = items;
			setFormData(data);
			return;
		} else {
			try {
				const response = await deleteBundleItem(sanitizeValue(params.id), itemId);
				if (response?.success) {
					
					items = items.filter(elem => elem.itemId !== itemId);
					data.items = items;
					setFormData(data);
				}
			} catch (err) {
				console.error(err);
				showNotification("error", "Unable to remove item");
			}
		}
	};
  
	const handleCancel = () => {
		navigate(-1);
	};

	return (
   		<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className="text-left text-2xl font-Poppins font-semibold ml-5">
					Edit Item
				</span>
			</div>
        	{formData ? (
            	<form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Item Type <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
							<Select
								name="itemType"
								value={`${formData?.itemType}`}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
								{itemTypes?.map((item: ItemType) => (
									<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
								))}
							</Select>
                		</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Brand Category <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
                			<Select
								name="brandCategory"
								value={`${formData?.brandCategory}`}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
                        		{brandCategories?.map((item: BrandCategory) => (
                            		<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        		))}
                    		</Select>
                		</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Product Category <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
                			<Select
								name="productCategory"
								value={`${formData?.productCategory}`}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
                    			{productCategories?.map((item: ProductCategory) => (
                            		<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        		))}
                    		</Select>
                		</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Brand Code <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
                			<Select
								name="brand"
								value={`${formData?.brand}`}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
                    			{brands?.map((item: Brand) => (
                            		<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                        		))}
                    		</Select>
                		</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Parent Code <span className='text-red-400'>*</span></h2>
                		<TextField
							name="parentCode"
							placeholder="Enter Parent Code"
							variant="outlined"
							value={formData?.parentCode}
							onChange={handleInputChange}
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
							className='bg-slate-100 dark:bg-[#202829] rounded-lg'
						/>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Item Code <span className='text-red-400'>*</span></h2>
                		<TextField
							name="itemCode"
							placeholder="Enter Item Code"
							variant="outlined"
							value={formData?.itemCode}
							onChange={handleInputChange}
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
							className='bg-slate-100 dark:bg-[#202829] rounded-lg'
						/>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Item Name <span className='text-red-400'>*</span></h2>
                		<TextField
							name="itemName"
							placeholder="Enter Item Name"
							variant="outlined"
							value={formData?.itemName}
							onChange={handleInputChange}
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
                    		className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                		/>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">UOM <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
                			<Select
								name="uom"
								value={`${formData?.uom}`}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
                    			{uoms?.map((item: Uom) => (
                            		<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.uomGroup}</MenuItem>
                        		))}
                    		</Select>
                		</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Size <span className='text-red-400'>*</span></h2>
						<FormControl fullWidth>
							<Select
								name="size"
								value={formData?.size}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
								{sizes?.map((item: Size) => (
									<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.size}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
                	<div>
						<h2 className="text-sm font-Poppins font-base mb-2">Target Days Cover <span className='text-red-400'>*</span></h2>
						<FormControl fullWidth>
							<Select
								name="classification"
								value={formData?.classification}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
							>
								{classifications?.map((item: Classification) => (
									<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.classification}</MenuItem>
								))}
							</Select>
						</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Purchase Price <span className='text-red-400'>*</span></h2>
                		<TextField
							name="purchasePrice"
							placeholder="Enter Purchase Price"
							variant="outlined"
							value={formData?.purchasePrice === 0 ? "" : formData?.purchasePrice}
							onChange={handleInputChange}
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
							className='bg-slate-100 dark:bg-[#202829] rounded-lg'
						/>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Currency <span className='text-red-400'>*</span></h2>
                		<FormControl fullWidth>
            				<Select
								name="currency"
								value={formData?.currency}
								onChange={handleSelectChange}
								displayEmpty
								inputProps={{ className: 'dark:text-white text-black' }}
								sx={selectStyle}
								className='bg-slate-100 dark:bg-[#202829]'
                			>
                				{currencies?.map((item: Currency) => (
                        			<MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.currency}</MenuItem>
                    			))}
                			</Select>
            			</FormControl>
                	</div>
                	<div>
                		<h2 className="text-sm font-Poppins font-base mb-2">Suggested Retail Price <span className='text-red-400'>*</span></h2>
						<TextField
							name="suggestedRetailPrice"
							placeholder="Enter Suggested Retail Price"
							variant="outlined"
							value={formData?.suggestedRetailPrice === 0 ? "" : formData?.suggestedRetailPrice}
							onChange={handleInputChange}
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
							className='bg-slate-100 dark:bg-[#202829] rounded-lg'
						/>
                	</div>
                	<div>
    					<span className="text-sm font-Poppins font-base mb-2">Item Category <span className='text-red-400'>*</span></span>
						<TextField
							placeholder="Item Category"
							variant="outlined"
							defaultValue={formData?.itemCategory}
							disabled
							inputProps={{ className: 'dark:text-white text-black' }}
							sx={inputStyle}
							className='bg-slate-100 dark:bg-[#202829] rounded-lg'
						/>
                	</div>
					
            	</form>
        	) : (
            	<p>Loading</p>
        	)}
			{formData?.itemCategory?.toUpperCase() === "BUNDLE" && (
				<div className="flex flex-col">
					{formData?.items?.map((item: BundleItemPayload, index: number) => (
						<div className="flex justify-center items-center gap-4" key={index}>
							<div>
								<span className="text-sm font-Poppins font-base mb-2">Item Code</span>
								<FormControl fullWidth>
									<Select
										name="itemData"
										value={`${item.itemId}_${item.itemCode}`}
										onChange={(e) => handleItemChange(index, e)}
										displayEmpty
										inputProps={{ className: "dark:text-white text-black" }}
										sx={selectStyle}
										className="bg-slate-100 dark:bg-[#202829]"
									>
										{items?.map((item: Item) => (
											<MenuItem key={item.id} value={`${item.id}_${item.itemCode}`} sx={{ fontFamily: "Poppins, sans-serif" }} >
												{item.itemCode}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div>
								<span className="text-sm font-Poppins font-base mb-2">Item Name</span>
								<FormControl fullWidth>
									<Select
										disabled
										value={`${item.itemId}_${item.itemCode}`}
										displayEmpty
										inputProps={{ className: "dark:text-white text-black" }}
										sx={selectStyle}
										className="bg-slate-100 dark:bg-[#202829]"
									>
										{items?.map((item: Item) => (
											<MenuItem key={item.id} value={`${item.id}_${item.itemCode}`} sx={{ fontFamily: "Poppins, sans-serif" }} >
												{item.itemName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div>
								<span className="text-sm font-Poppins font-base mb-2">Quantity <span className='text-red-400'>*</span></span>
								<TextField
									name="quantity"
									value={item.quantity === 0 ? '' : item.quantity}
									onChange={(e) => handleItemChange(index, e)}
									fullWidth
									inputProps={{ className: 'dark:text-white text-black' }}
									sx={inputStyle}
									className='bg-slate-100 dark:bg-[#202829] rounded-lg'
								/>	
							</div>
							<MdDeleteForever onClick={() => handleRemoveItem(sanitizeValue(item?.itemId), index)} className="text-red-500 text-2xl cursor-pointer" />
						</div>
					))}
					<div className='flex justify-center items-center mt-6'>
						<button type="button" onClick={handleAddItem} className='add-more-btn'>
							Add More Items
						</button>
					</div>
				</div>
			)}
        	<div className='flex justify-center mt-8 space-x-12 items-center'>
            	<button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                	<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                	<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                	<span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                	<span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
            	</button>
            	<button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                	<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                	<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                	<span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                	<span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            	</button>
        	</div>
    	</main>
  	);
};
