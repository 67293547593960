import { useEffect, useState } from 'react';
import {
	TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import { Company, LoginResult } from '@/lib/models';
import { queryConfigs } from "../../react-query/queryConfig";
import { sanitizeValue, showNotification } from '../../lib/helper';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = ["Company Name", "Created On", ""];
const limit = 10;

export default function Companies({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getCompanies, queryKey: companyKey } = queryConfigs.getCompanies;
    const { mutationFn: deleteCompany } = queryConfigs.deleteCompany;
    const [searchValue, setSearchValue] = useState("");
    const [searchForm, setSearchForm] = useState({ name: "" });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState<number | null>(null);
    const [companyList, setCompanyList] = useState<Company[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Company Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: companies, refetch } = useGetQuery({
        key: companyKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            name: searchForm.name ?? "",
        },
        func: getCompanies,
    });

    const mutateDelete = useMutationQuery({ key: companyKey, func: deleteCompany, onSuccess });

    useEffect(() => {
        if (companies) {
            const list = companies?.result?.list as Company[];
            setCompanyList(list);
        }
    }, [companies]);

    const handleAddClick = () => {
        navigate('add-company');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-company/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setCompanyToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (companyToDelete) {
            mutateDelete.mutate(companyToDelete);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            name: searchValue ?? ""
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchForm({ name: "" });
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(companies?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
                showReloadBtn={true}
                reload={handleReload}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				cred={cred}
                children1={
                    <>
                      <div className=" flex items-center space-x-5 w-full">
                        <TextField
                          variant="outlined"
                          placeholder='Enter Company Name'
                          name="name"
                          value={searchValue}
                          onChange={handleNameChange}
                          size="small"
                          inputProps={{
                            className: 'dark:text-white text-black',
                            }}
                            sx={{
                            width: 300,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.5rem',
                                height: '44px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                            }}
                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                        />      
                        <button onClick={handleSearch}  className="search-btn">
                          Search
                        </button>
                        <button onClick={handleSearchClear}  className="clear-btn">
                          Clear
                        </button>
                      </div>
                    </>
                  }
            >
                {companyList?.map((company: Company) => (
                    <TableRow key={company.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">
                            {company?.name}
                        </TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">
                        {new Date(company?.createdOn).toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric',}).replace(/(\s)(\d{4})$/, ', $2')}                      
                        </TableCell>
                        <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
							<div className='space-x-2'>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
										<button className="edit-btn" onClick={() => handleEditClick(company.id)}>
											<FaEdit />
										</button>
									</Tooltip>
								}
								{cred?.hasDelete === 1 &&
									<Tooltip title="Delete" arrow>
										<button className="del-btn" onClick={() => handleDeleteClick(company.id)}>
											<MdDeleteForever />
										</button>
									</Tooltip>
								}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}  
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Company?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};





