import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { BrandPayload } from '../../lib/payload';
import { Brand, Company } from '../../lib/models';
import { queryConfigs } from '../../react-query/queryConfig';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { getBrand, getCompanies } from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function EditBrand() {
    const { mutationFn, queryKey } = queryConfigs.updateBrand
    const [formData, setFormData] = useState<BrandPayload | null>(null);
    const [ companies, setCompanies ] = useState<Company[]>([]);
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams ();
	const onSuccess = () => {
		showNotification("success", "Brand Updated");
		navigate(-1);
	};
	const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

  useEffect(() => {
      if (params.id) {
          fetchBrand(sanitizeValue(params.id));
      }
  }, [params]);

  const fetchBrand = async (id: number) => {
      try {
          const response = await getBrand(id);
          if (response?.success) {
              const result = response?.result as Brand;
              setFormData({
                  code: result.code,
                  name: result.name,
                  company: result.company,
                  landedCostFactor: result.landedCostFactor,
                  retailerMargin: result.retailerMargin,     
              });
          }
      } catch (err) {
          console.error(err);
          showNotification("error", "Unable to get item Type  details");
      }
  };

  useEffect(() => {
      fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
      try {
          const response = await getCompanies({offset:0 , limit: 1000});
          if (response?.success) {
              setCompanies (response?.result?.list as Company [] ?? []);
          }
      } catch (err) {
          console.error(err);
      }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value as string,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
        if (params.id && formData && validate(formData, "brand")) {
            formData.company = sanitizeValue(formData.company);
            formData.landedCostFactor = sanitizeValue(formData.landedCostFactor);
            formData.retailerMargin = sanitizeValue(formData.retailerMargin);
            updateMutation.mutate({id: params?.id, body: formData});
        }
        else {
            showNotification("error", "Missing Required Field");
        }
    };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Edit Brand	
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
        <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Brand Code <span className='text-red-400'>*</span></h2>
            <TextField
                name="code"
                placeholder="Enter Brand  Name"
                variant="outlined"
                value={formData?.code}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Brand Name <span className='text-red-400'>*</span></h2>
            <TextField
                name="name"
                placeholder="Enter Brand Code"
                variant="outlined"
                value={formData?.name}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Company Name <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="company"
                value={`${formData?.company}`}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                    {companies?.map((item: Company) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Landed Cost Factor <span className='text-red-400'>*</span></h2>
            <TextField
                name="landedCostFactor"
                placeholder="Enter Landed Cost Factor"
                variant="outlined"
                value={formData?.landedCostFactor === 0 ? '' : formData?.landedCostFactor}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Retailer Margin (%) <span className='text-red-400'>*</span></h2>
            <TextField
                name="retailerMargin"
                placeholder="Enter Retailer Margin"
                variant="outlined"
                value={formData?.retailerMargin === 0 ? '' : formData?.retailerMargin}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
            <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                <span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
            </button>
            <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
        </div>
    </main>
  );
};
