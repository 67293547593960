import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField,
	Box, Modal,
} from '@mui/material';
import CommonTable from '../CommonTable';
import { queryConfigs } from '../../react-query/queryConfig';
import { useNavigate } from 'react-router-dom';
import { Customer, Outbound, Warehouse, LoginResult } from '../../lib/models';
import { useTheme } from '../../ThemeContext';
import { sanitizeValue } from '../../lib/helper';
import { useGetQuery  } from '../../react-query/queryHook';
import { getCustomers, getWarehouses, getExportURL } from '../../lib/api';
import Import from "../Import";

const userHeaderNames: string[] = [
    "Date", "Warehouse", "Item Code", "Parent Code",
    "Item Name", "Uom Group", "PO Quantity",
    "Quantity in Pieces", "Customer Name",
    "PO Number",
];
const limit = 10;

export default function OutBounds ({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getOutbounds, queryKey: outboundKey } = queryConfigs.getOutbounds;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemName");
    const [searchForm, setSearchForm] = useState({
        itemName: "", itemCode: "", warehouse: "",
        customer: "", startDate: "", endDate: "",
        poNumber: "",
    });
    const navigate = useNavigate();
    const [outboundList, setOutboundList] = useState<Outbound[]>([])
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])
    const [customers, setCustomers] = useState<Customer[]>([])
	const [currentPage, setCurrentPage] = useState(1);
	const [openImport, setOpenImport] = useState(false);
    const { theme } = useTheme();

	const boxStyles = {
		position: 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: theme === 'dark' ? '#20293D' : 'white',
		color: theme === 'dark' ? 'white' : 'black',
		borderRadius: '10px',
		boxShadow: 24,
	};

    const { data: outbounds, refetch } = useGetQuery({
        key: outboundKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
			itemName: searchForm?.itemName ?? "",
			itemCode: searchForm?.itemCode ?? "",
			warehouse: searchForm?.warehouse ?? "",
			customer: searchForm?.customer ?? "",
			startDate: searchForm?.startDate ?? "",
			endDate: searchForm?.endDate ?? "",
        },
        func: getOutbounds,
    });

    useEffect(() => {
        fetchWarehouses();
        fetchCustomers();
    }, []);

    const fetchWarehouses = async () => {
        try {
            const response = await getWarehouses({offset:0 , limit: 1000});
            if (response?.success) {
                setWarehouses (response?.result?.list as Warehouse [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };
    
    const fetchCustomers = async () => {
        try {
            const response = await getCustomers({offset:0 , limit: 1000});
            if (response?.success) {
                setCustomers (response?.result?.list as Customer [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (outbounds) {
            const list = outbounds?.result?.list as Outbound[];
            setOutboundList(list);
        }
    }, [outbounds]);

    const handleAddClick = () => {
        navigate('add-outbound');
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            itemName: searchType === "itemName" ? searchValue: "",
            itemCode: searchType === "itemCode" ? searchValue: "",
            warehouse: searchType === "warehouse" ? searchValue: "",
            customer: searchType === "customer" ? searchValue: "",
            startDate: searchType === "startDate" ? searchValue: "",
            endDate: searchType === "endDate" ? searchValue: "",
            poNumber: searchType === "poNumber" ? searchValue: "",
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemName");
		setSearchForm({
			itemName: "", itemCode: "", warehouse: "",
			customer: "", poNumber: "", startDate: "",
			endDate: "",
		});
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    }

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

	const exportHandler = () => {
		let url: string = getExportURL("outbounds");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&itemName=${searchForm?.itemName ?? ""}&
			parentCode=${searchForm?.itemCode ?? ""}&warehouse=${searchForm?.warehouse ?? ""}&
			customer=${searchForm?.customer ?? ""}&startDate=${searchForm?.startDate ?? ""}&
			endDate=${searchForm?.endDate ?? ""}&poNumber=${searchForm?.poNumber ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(outbounds?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                marginTop={8}
                componentType='stockOut'
                onAddClick={handleAddClick}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				onExportClick={exportHandler}
				onImportClick={() => setOpenImport(true)}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="itemName" style={{ fontFamily: 'Poppins' }}>Item Name</MenuItem>
                                    <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                    <MenuItem value="poNumber" style={{ fontFamily: 'Poppins' }}>Po Number</MenuItem>
                                    <MenuItem value="warehouse" style={{ fontFamily: 'Poppins' }}>Warehouse</MenuItem>
                                    <MenuItem value="customer" style={{ fontFamily: 'Poppins' }}>Customer</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["itemName", "itemCode", "poNumber"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                            }}
                                            sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            }}
                                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"warehouse".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Warehouse</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {warehouses?.map((item: Warehouse) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {"customer".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Customer</h2>
                                    <FormControl sx={{ width: "50%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {customers?.map((item: Customer) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {outboundList?.map((outbound : Outbound) =>
                <TableRow key={outbound.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">
                {new Date(outbound?.createdOn).toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric', }).replace(/(\s)(\d{4})$/, ', $2')}
                </TableCell>
                <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{outbound.warehouseCode}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.itemCode}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.parentCode}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600, width: '300px',}} className="capitalize text-gray-800 dark:text-white">{outbound.itemName}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.uomGroup}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.poQuantity}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.pieces}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.customerName}</TableCell>
                <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{outbound.poNumber}</TableCell>
            </TableRow>
                )}
            </CommonTable>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={boxStyles} >
					<Import fileType="outbounds" refresh={refetch} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
