import { useEffect, useState } from 'react';
import { TableCell, TableRow, MenuItem, Select, FormControl } from '@mui/material';
import CommonTable from "../CommonTable";
import { useNavigate } from 'react-router-dom';
import { queryConfigs } from '../../react-query/queryConfig';
import { sanitizeValue } from '../../lib/helper';
import { useGetQuery } from '../../react-query/queryHook';
import { Demand, LoginResult } from '../../lib/models';

const userHeaderNames: string[] = [
	"Brand Code", "Parent Code", "Item Name", "M",
    "M+1", "M+2", "Average",
];

const limit = 10;

export default function AvgDemand({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getAverageDemands, queryKey: demandKey } = queryConfigs.getAverageDemands;
    const navigate = useNavigate();
    const [demandList, setDemandList] = useState<Demand[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);

    const { data: demands, refetch } = useGetQuery({
        key: demandKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            year: selectedYear,
            month: selectedMonth,
        },
        func: getAverageDemands,
    });

    useEffect(() => {
        if (demands) {
            const list = demands?.result?.list as Demand[];
            setDemandList(list || []);
        }
    }, [demands]);

    const handleAddClick = () => {
        navigate('add-avgDemand');
    };

    const handleEditAllClick = () => {
        navigate(`edit-all-avgDemand?year=${selectedYear}&month=${selectedMonth}`);
    };

    const handleChangeYear = (event: any) => {
        const year = event.target.value as number;
        setSelectedYear(year);
        refetch(); 
    };

    const handleMonthChange = (event: any) => {
        setSelectedMonth(event.target.value as number);
        refetch();
    };

    const hasDataForSelectedMonth = demandList.some(demand => {
        return (
            demand.month === selectedMonth && 
            demand.year === selectedYear
        );
    });

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(demands?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                marginTop={8}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onAddClick={!hasDataForSelectedMonth ? handleAddClick : undefined}
                onEditAllClick={hasDataForSelectedMonth ? handleEditAllClick : undefined}
                reload={refetch}
				cred={cred}
                children2={
                    <div className="flex justify-end space-x-4 mb-4">
                        {/* Year Selector */}
                        <FormControl fullWidth>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Year</h2>
                            <Select
                                name="year"
                                id="year-select"
                                value={selectedYear || ''}
                                onChange={handleChangeYear}
                                displayEmpty
                                inputProps={{
                                    className: 'dark:text-white text-black',
                                }}
                                sx={{
                                    width: '100%',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '14px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '0.5rem',
                                        height: '44px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                    },
                                }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                            >
                                {Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Month Selector */}
                        <FormControl fullWidth>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Month</h2>
                            <Select
                                id="month-select"
                                value={selectedMonth || ''}
                                onChange={handleMonthChange}
                                displayEmpty
                                inputProps={{
                                    className: 'dark:text-white text-black',
                                }}
                                sx={{
                                    width: 300,
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '14px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '0.5rem',
                                        height: '44px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                    },
                                }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                            >
                                {Array.from({ length: 12 }, (_, index) => (
                                    <MenuItem key={index + 1} value={index + 1}>
                                        {new Date(0, index).toLocaleString('default', { month: 'long' })}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                }
            >
                {demandList?.map((demand: Demand) =>
                    <TableRow key={demand.id} style={{ height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.brandCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.parentCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{demand.itemName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.firstMonth}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.secondMonth}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.thirdMonth}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{demand.average}</TableCell>
                    </TableRow>
                )}
            </CommonTable>
        </div>
    );
}
