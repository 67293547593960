import Low from '../../assets/low.png';
import No from '../../assets/out.png';
import Over from '../../assets/over.png';

type Props = {
	clickHandler: () => void;
	title: string;
	count: number;
	cardType: string;
};

export default function DashboardCard({ clickHandler, title, count, cardType }: Props) {
	const getImageSrc = () => {
		switch(cardType) {
			case "noStock":
				return No;
			case "lowStock":
				return Low;
			case "overStock":
				return Over;
			default:
				return No
		}
	};
	return (
		<button onClick={clickHandler} className={`w-[20%] cursor-pointer z-30 bg-custom-gradient dark:bg-[#20293D] flex flex-col items-center p-2 rounded-lg drop-shadow-lg justify-center`}>
			<div className='flex justify-center items-center gap-4'>
				<img src={getImageSrc()} alt='noStock' className='w-[40px]' />
				<h3 className="capitalize text-base font-Poppins font-light text-white">
					{title}
				</h3>
			</div>
			<div className='flex justify-center items-center'>
				<p className="text-3xl font-black font-Poppins text-white">
					{count}
				</p>
			</div>
		</button>
	);	
}
