import { useEffect, useState } from 'react';
import {
	TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { Uom, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = ["UOM Group", "Multiplier", ""];
const limit = 10;

export default function Uoms({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getUoms, queryKey: uomKey } = queryConfigs.getUoms;
    const { mutationFn: deleteUom } = queryConfigs.deleteUom;
    const [searchValue, setSearchValue] = useState("");
    const [searchForm, setSearchForm] = useState({ group: "" });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [uomToDelete, setUomToDelete] = useState<number | null>(null);
    const [uomList, setUomList] = useState<Uom[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Product Category Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: uoms, refetch } = useGetQuery({
        key: uomKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            group: searchForm.group ?? "",
        },
        func: getUoms,
    });

    const mutateDelete = useMutationQuery({ key: uomKey, func: deleteUom, onSuccess });

    useEffect(() => {
        if (uoms) {
            const list = uoms?.result?.list as Uom[];
            setUomList(list);
        }
    }, [uoms]);

    const handleAddClick = () => {
        navigate('add-unit');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-unit/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setUomToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (uomToDelete) {
            mutateDelete.mutate(uomToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            group: searchValue ?? ""
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchForm({ group: "" });
    };

	const handleReload = () => {
		refetch();
		//handleSearchClear();
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(uoms?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				cred={cred}
                children1={
                    <>
                      <div className=" flex items-center space-x-5 w-full">
                        <TextField
                          variant="outlined"
                          placeholder='Enter Role Name'
                          name="group"
                          value={searchValue}
                          onChange={handleNameChange}
                          size="small"
                          sx={{
                            width: "20%",
                            "& .MuiInputLabel-root": {
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "13px",
                            },
                          }}
                        />      
                        <button onClick={handleSearch}  className="search-btn">
                          Search
                        </button>
                        <button onClick={handleSearchClear}  className="clear-btn">
                          Clear
                        </button>
                      </div>
                    </>
                  }
            >
                {uomList?.map((uom: Uom) =>(
					<TableRow key={uom.id} style={{height: '60px'}}>
						<TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 bg-white dark:bg-black dark:text-white">{uom.uomGroup}</TableCell>
						<TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white bg-white dark:bg-black">{uom.multiplier}</TableCell>
						<TableCell align='center' className="capitalize text-gray-800 dark:text-white bg-white dark:bg-black">
							<div className='flex justify-center items-center space-x-2'>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
										<button className="edit-btn" onClick={() => handleEditClick(uom.id)}>
											<FaEdit />
										</button>
									</Tooltip>
								}
								{cred?.hasDelete === 1 &&
									<Tooltip title="Delete" arrow>
										<button className="del-btn" onClick={() => handleDeleteClick(uom.id)}>
											<MdDeleteForever />
										</button>
									</Tooltip>
								}
							</div>
						</TableCell>
					</TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this UOM?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};
