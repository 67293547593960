import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { Brand, Company, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import { getCompanies } from '../../lib/api';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = [
    "Brand Code",
    "Brand Name",
    "Company Name",
    "Landed Cost Factor",
    "Retailer Margin",
    ""
];
const limit = 10;

export default function Brands({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getBrands, queryKey: brandKey } = queryConfigs.getBrands;
    const { mutationFn: deleteBrand } = queryConfigs.deleteBrand;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("code");
    const [searchForm, setSearchForm] = useState({
        code: "",
        name: "",
        company: "",
    });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [brandToDelete, setBrandToDelete] = useState<number | null>(null);
    const [ companies, setCompanies ] = useState<Company[]>([]);
    const [brandList, setBrandList] = useState<Brand[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Product Category Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: brands, refetch } = useGetQuery({
        key: brandKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            code: searchForm?.code ?? "",
            name: searchForm?.name ?? "",
            company: searchForm?.company ?? "",
        },
        func: getBrands,
    });

    const mutateDelete = useMutationQuery({ key: brandKey, func: deleteBrand, onSuccess });

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await getCompanies({offset:0 , limit: 1000});
            if (response?.success) {
                setCompanies (response?.result?.list as Company [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (brands) {
            const list = brands?.result?.list as Brand[];
            setBrandList(list);
        }
    }, [brands]);

    const handleAddClick = () => {
        navigate('add-brand');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-brand/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setBrandToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (brandToDelete) {
            mutateDelete.mutate(brandToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            code: searchType === "code" ? searchValue : "",
            name: searchType === "name" ? searchValue : "",
            company: searchType === "company" ? searchValue : "",
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("salesCategory");
        setSearchForm({ code: "", name: "", company: "" });
		setCurrentPage(1);
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    }

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(brands?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="name" style={{ fontFamily: 'Poppins' }}>Name</MenuItem>
                                    <MenuItem value="code" style={{ fontFamily: 'Poppins' }}>Brand Code</MenuItem>
                                    <MenuItem value="company" style={{ fontFamily: 'Poppins' }}>Company</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["name", "code"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                            }}
                                            sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            }}
                                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"company".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Company</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {companies?.map((item: Company) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {brandList?.map((brand: Brand) => (
                    <TableRow key={brand.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{brand.code}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{brand.name}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{brand.companyName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{brand.landedCostFactor}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{brand.retailerMargin} %</TableCell>
                        <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
                        <div className='flex justify-center items-center space-x-2'>
							{cred?.hasEdit === 1 &&
								<Tooltip title="Edit" arrow>
									<button className="edit-btn" onClick={() => handleEditClick(brand.id)}>
										<FaEdit />
									</button>
								</Tooltip>
							}
							{cred?.hasDelete === 1 &&
								<Tooltip title="Delete" arrow>
									<button className="del-btn" onClick={() => handleDeleteClick(brand.id)}>
										<MdDeleteForever />
									</button>
								</Tooltip>
							}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Brand?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};
