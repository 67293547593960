import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { queryConfigs } from '../../react-query/queryConfig';
import { CustomerPayload, PersonInChargePayload } from '../../lib/payload';
import { validate } from '../../lib/validation';
import { Channel, SalesCategory, User } from '../../lib/models';
import { getChannels, getSalesCategories, getUsers } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";

export default function AddCustomer() {
    const { mutationFn, queryKey } = queryConfigs.addCustomer
    const [formData, setFormData] = useState<CustomerPayload>({
        name: '',
        salesCategory: 0,
        channel: 0,
        personInCharge: [],
    });
    const [ salesCategories, setSalesCategories ] = useState<SalesCategory[]>([]);
    const [ channels, setChannels ] = useState<Channel[]>([]);
    const [ users, setUsers ] = useState<User[]>([]);
    const [inchargeList, setInchargeList] = useState("");

    const theme = useTheme();
    const navigate = useNavigate();
    const onSuccess = () => {
        showNotification("success", "Customer Added");
        navigate(-1);
    };

    const addMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        fetchSalesCategories();
        fetchChannels();
        fetchUsers();
    }, []);

    const fetchSalesCategories = async () => {
        try {
            const response = await getSalesCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setSalesCategories (response?.result?.list as SalesCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchChannels = async () => {
        try {
            const response = await getChannels({offset:0 , limit: 1000});
            if (response?.success) {
                setChannels (response?.result?.list as Channel [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await getUsers({offset:0 , limit: 1000});
            if (response?.success) {
                setUsers (response?.result?.list as User [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleInputChange = (e: any) => {
    const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        };

    const handleSubmit = (e: any) => {
            e.preventDefault();
            if (inchargeList?.length > 0) {
                const personInCharge: PersonInChargePayload[] = [];
                const inchargeSplit = inchargeList.split(",");
                for (let item of inchargeSplit) {
                    personInCharge.push({
                        user: sanitizeValue(Number(item)),
                    });
                }
                formData.personInCharge = personInCharge;
                if (validate(formData, "customer")) {
                    formData.salesCategory = sanitizeValue(formData.salesCategory);
                    formData.channel = sanitizeValue(formData.channel);
                    addMutation.mutate(formData);
                }
                else {
                    showNotification("error", "Missing Required Field");
                }
            } else {
                showNotification("error", "Person In Charge Cannot be Empty");
            }
        };

        const handleSelectChange = (e: any) => {
            const { name, value } = e.target;
            setFormData({
            ...formData,
            [name as string]: value as string,
            });
        };

    const handleCancel = () => {
            navigate(-1);
        };

return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Add Customer
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
        <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Sales Category <span className='text-red-400'>*</span></h2>
                <FormControl fullWidth>
                <Select
                    name="salesCategory"
                    value={formData.salesCategory}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{
                        className: 'dark:text-white text-black',
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.5rem',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        },
                        '& .MuiSelect-select': {
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        },
                        '& .MuiSelect-icon': {
                        color: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-input': {
                        padding: '10px 14px',
                        },
                    }}
                    className='bg-slate-100 dark:bg-[#202829]'
                    >
                        {salesCategories?.map((item: SalesCategory) => (
                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </div>
                <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Channel <span className='text-red-400'>*</span></h2>
                <FormControl fullWidth>
                <Select
                    name="channel"
                    value={formData.channel}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{
                        className: 'dark:text-white text-black',
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.5rem',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        },
                        '& .MuiSelect-select': {
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        },
                        '& .MuiSelect-icon': {
                        color: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-input': {
                        padding: '10px 14px',
                        },
                    }}
                    className='bg-slate-100 dark:bg-[#202829]'
                    >
                        {channels?.map((item: Channel) => (
                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Customer Name <span className='text-red-400'>*</span></h2>
            <TextField
                name="name"
                placeholder="Enter Customer Name"
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: "100%",
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Person in Charge <span className='text-red-400'>*</span></h2>
                <Autocomplete
                    multiple
                    id="person-in-charge-autocomplete"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        setInchargeList(value.map((user: User) => user.id).join(','));
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            className: 'dark:text-white text-black',
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                            borderRadius: '0.5rem',
                            height: 'auto',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                            },
                            '& .MuiSelect-select': {
                            height: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            },
                            '& .MuiSelect-icon': {
                            color: theme.palette.text.primary,
                            },
                            '& .MuiOutlinedInput-input': {
                            padding: '10px 14px',
                            height: 'auto',
                            },
                        }}
                        className="bg-slate-100 dark:bg-[#202829] rounded-lg"
                        />
                    )}
                />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
                <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                    <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
    </main>
    );
};
