import { StockItem } from "../../lib/models";
import { Box } from "@mui/material";

type Props = {
	title: string;
	items: StockItem[];
	theme: "light" | "dark";
	handleClose: () => void;
};

const tableStyle = "min-w-full bg-white dark:bg-[#20293D] overflow-hidden";
const thStyle = "py-2 px-4 text-left text-white";
const btnStyle = "bg-[#7c5082] dark:bg-[#bb7ac4] text-white font-semibold py-2 px-4 rounded-lg";

export default function StockList({ title, theme, items, handleClose }: Props) {
	const boxStyles = {
        position: 'absolute',
        top: '50%',
		maxHeight: "80vh",
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme === 'dark' ? '#20293D' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
		overflow: "auto",
	};

	const tdStyle = `
		py-2 px-4 text-[13px] ${theme === 'dark' ? 'text-white' : 'text-gray-800'}
	`;

	return (
		<Box sx={boxStyles}>
			<h3 className="capitalize text-lg font-bold mb-4" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
				{title} :
                    </h3>
                    <table className={tableStyle}>
                        <thead className='bg-[#7c5082]'>
                            <tr>
                                <th className={`${thStyle} w-[150px]` }>Item Code</th>
                                <th className={thStyle}>Item Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items?.map((item: StockItem) => (
                                <tr key={item.id} className={`border ${theme === 'dark' ? 'bg-[#2c2f3b]' : 'bg-white'}`}>
                                    <td className={tdStyle}>{item.itemCode}</td>
                                    <td className={tdStyle}>{item.itemName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Close button */}
                    <div className="flex justify-end mt-4">
                        <button onClick={handleClose} className={btnStyle}>Close</button>
                    </div>
                </Box>
	);
}
