import { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { queryConfigs } from '../../react-query/queryConfig';
import { BrandPayload } from '../../lib/payload';
import { Company } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { getCompanies } from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function AddBrand() {
    const { mutationFn, queryKey } = queryConfigs.addBrand
    const [formData, setFormData] = useState<BrandPayload>({
        code: '',
        name: '',
        company: 0,
        landedCostFactor: 0,
        retailerMargin: 0,
        });
    const [ companies, setCompanies ] = useState<Company[]>([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const onSuccess = () => {
        showNotification("success", "Brand Added");
        navigate(-1);
        };

    const addMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        try {
            const response = await getCompanies({offset:0 , limit: 1000});
            if (response?.success) {
                setCompanies (response?.result?.list as Company [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };


    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name as string]: value as string,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate(formData, "brand")) {
            formData.company = sanitizeValue(formData.company);
            formData.landedCostFactor = sanitizeValue(formData.landedCostFactor);
            formData.retailerMargin = sanitizeValue(formData.retailerMargin);
            addMutation.mutate(formData);
        }
        else {
            showNotification("error", "Missing Required Field");
        }
    };
    const handleCancel = () => {
        navigate(-1);
    };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Add Brand
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Brand Code <span className='text-red-400'>*</span></h2>
            <TextField
                name="code"
                placeholder="Enter Brand  Name"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Brand Name <span className='text-red-400'>*</span></h2>
            <TextField
                name="name"
                placeholder="Enter Brand Code"
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Company Name <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="company"
                value={formData.company}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                    {companies?.map((item: Company) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Landed Cost Factor <span className='text-red-400'>*</span></h2>
            <TextField
                name="landedCostFactor"
                placeholder="Enter Landed Cost Factor"
                variant="outlined"
                value={formData.landedCostFactor === 0 ? '' : formData.landedCostFactor}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
            <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Retailer Margin (%) <span className='text-red-400'>*</span></h2>
            <TextField
                name="retailerMargin"
                placeholder="Enter Retailer Margin"
                variant="outlined"
                value={formData.retailerMargin === 0 ? '' : formData.retailerMargin}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
            </div>
        </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
                <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                    <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
    </main>
  );
};
