import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { queryConfigs } from '../../react-query/queryConfig';
import { VendorPayload } from '../../lib/payload';
import { validate } from '../../lib/validation';
import { Country } from '../../lib/models';
import { getCountries } from '../../lib/api';
import { IoMdArrowBack } from "react-icons/io";


export default function AddVendor() {
    const { mutationFn, queryKey } = queryConfigs.addVendor
    const [formData, setFormData] = useState<VendorPayload>({
        name: '',
        code: '',
        country: 0,
        });
    const [ countries, setCountries ] = useState<Country[]>([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const onSuccess = () => {
        showNotification("success", "Vendor Added");
        navigate(-1);
    };

    const addMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        fetchCountries();
    }, []);
  
    const fetchCountries = async () => {
        try {
            const response = await getCountries({offset:0 , limit: 1000});
            if (response?.success) {
                setCountries (response?.result?.list as Country [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleInputChange = (e: any) => {
    const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        };

    const handleSubmit = (e: any) => {
            e.preventDefault();
            if (validate(formData, "country")) {
                formData.country = sanitizeValue(formData.country);
                addMutation.mutate(formData);
            }
            else {
                showNotification("error", "Missing Required Field");
            }
        };

    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name as string]: value as string,
        });
    };

    const handleCancel = () => {
            navigate(-1);
        };

return (
<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
	<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Add Vendor
			</span>
		</div>
    <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
        <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Vendor Code <span className='text-red-400'>*</span></h2>
            <TextField
                name="code"
                placeholder="Enter Vendor Code"
                variant="outlined"
                value={formData.code}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
        </div>
        <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Vendor Name <span className='text-red-400'>*</span></h2>
            <TextField
                name="name"
                placeholder="Enter Vendor Name"
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
                inputProps={{
                className: 'dark:text-white text-black',
                }}
                sx={{
                width: "100%",
                '& .MuiOutlinedInput-root': {
                    borderRadius: '0.5rem',
                    height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                }}
                className='bg-slate-100 dark:bg-[#202829] rounded-lg'
            />
        </div>
        <div>
            <h2 className="text-sm font-Poppins font-base mb-2">Country of Origin <span className='text-red-400'>*</span></h2>
            <FormControl fullWidth>
            <Select
                name="country"
                value={formData.country}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{
                    className: 'dark:text-white text-black',
                }}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    },
                    '& .MuiSelect-icon': {
                    color: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                    },
                }}
                className='bg-slate-100 dark:bg-[#202829]'
                >
                {countries?.map((item: Country) => (
                        <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    </form>
    <div className='flex justify-center mt-8 space-x-12 items-center'>
            <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">Add</span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
            </button>
            <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
        </div>
</main>
);
};
