import { isEmpty } from "./helper";
import {
    BrandCategoryPayload,
    BrandPayload,
    ChannelPayload,
    ClassificationPayload,
    CompanyPayload,
    CountryPayload,
    CurrencyPayload,
    CustomerPayload,
    InboundPayload,
    ItemPayload,
    ItemTypePayload,
    OutboundPayload,
    ProductCategoryPayload,
    RolePayload,
    SalesCategoryPayload,
    SizePayload,
    TransferPayLoad,
    UomPayload,
    UserPayload,
    WarehousePayload,
} from "./payload";

type Body = CompanyPayload | ItemTypePayload | BrandCategoryPayload | ProductCategoryPayload | BrandPayload |
            UomPayload | RolePayload | UserPayload | ItemPayload | ClassificationPayload | WarehousePayload |
            UserPayload | SalesCategoryPayload | ChannelPayload | CustomerPayload | CompanyPayload | CountryPayload
            | InboundPayload | OutboundPayload | SizePayload |CurrencyPayload | TransferPayLoad

const fields = {
    company : ["name"],
    itemType : ["name"],
    brandCategory : ["name"],
    productCategory : ["name", "itemType", "brandCategory"],
    brand : ["code", "name", "company"],
    uom : ["uomGroup", "multiplier"],
    role : ["name", "hasView", "hasAdd", "hasEdit", "hasDelete"],
    user : ["name", "userId", "email", "role"],
    item : [
        "itemType", "brandCategory", "productCategory", "brand", "parentCode", "itemCode", "classification",
        "itemName", "uom", "purchasePrice", "currency", "suggestedRetailPrice", "size","itemCategory"
    ],
    classification : ["classification", "targetDaysCover"],
    warehouse : ["code", "description", "location"],
    salesCategory : ["name"],
    channel : ["name"],
    customer : ["name", "salesCategory", "channel", "personInCharge"],
    country : ["name"],
    vendor : ["code", "name", "country"],
    inbound : ["warehouse", "item", "poNumber", "poQuantity", "uom", "vendor"],
    outbound : ["warehouse", "item", "poNumber", "poQuantity", "uom", "customer"],
    size : ["size"],
    currency : ["currency", "conversionRate"],
    transfer : ["fromWarehouse", "toWarehouse", "item", "poQuantity", "itrNumber", "uom"],
    demand : ["brand", "item", "month", "year", "firstMonth", "secondMonth", "thirdMonth"],
	demandUpdate: ["id", "firstMonth", "secondMonth", "thirdMonth"],
    login : ["email", "password"],
	changePassword: ["password", "newPassword"],
}

export function validate(body: Body, entity: string): boolean {
	for (const key of fields[entity as keyof typeof fields]) {
		if (isEmpty(body[key as keyof typeof body])) {
			return false;
		}
	}
	return true;
};

export function validateList(arr: Body[], entity: string): boolean {
	for (const item of arr) {
		if (!validate(item, entity)) {
			return false;
		}
	}
	return true;
}
