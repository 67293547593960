import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { queryConfigs } from '../../react-query/queryConfig';
import { BrandCategory, ItemType, ProductCategory, LoginResult } from '../../lib/models';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import { getBrandCategories, getItemTypes } from '../../lib/api';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = [ "Item Type", "Brand Category", "Product Category", ""];
const limit = 10;

export default function ProductCategories({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getProductCategories, queryKey: productCategoryKey } = queryConfigs.getProductCategories;
    const { mutationFn: deleteProductCategory } = queryConfigs.deleteProductCategory;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemType");
    const [searchForm, setSearchForm] = useState({
        name: "",
        itemType: "",
        brandCategory: "",

    });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [productCategoryToDelete, setProductCategoryToDelete] = useState<number | null>(null);
    const [ brandCategories, setBrandCategories ] = useState<BrandCategory[]>([]);
    const [ itemTypes, setItemTypes ] = useState<ItemType[]>([]);
    const [productCategoryList, setProductCategoryList] = useState<ProductCategory[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Product Category Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: productCategories, refetch } = useGetQuery({
        key: productCategoryKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            name: searchForm?.name ?? "",
            itemType: searchForm?.itemType ?? "",
            brandCategory: searchForm?.brandCategory ?? "",
        },
        func: getProductCategories,
    });

    const mutateDelete = useMutationQuery({ key: productCategoryKey, func: deleteProductCategory, onSuccess });

    useEffect(() => {
        fetchBrandCategories();
        fetchItemTypes();
    }, []);

    const fetchBrandCategories = async () => {
        try {
            const response = await getBrandCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setBrandCategories (response?.result?.list as BrandCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchItemTypes = async () => {
        try {
            const response = await getItemTypes({offset:0 , limit: 1000});
            if (response?.success) {
                setItemTypes (response?.result?.list as BrandCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (productCategories) {
            const list = productCategories?.result?.list as ProductCategory[];
            setProductCategoryList(list);
        }
    }, [productCategories]);

    const handleAddClick = () => {
        navigate('add-productCategory');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-productCategory/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setProductCategoryToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (productCategoryToDelete) {
            mutateDelete.mutate(productCategoryToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
      };
    
    const handleSearch = () => {
        setSearchForm({
            name: searchType === "name" ? searchValue : "",
            itemType: searchType === "itemType" ? searchValue : "",
            brandCategory: searchType === "brandCategory" ? searchValue : "",
        });
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemType");
        setSearchForm({ name: "", itemType: "", brandCategory: "" });
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(productCategories?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
                marginTop={8}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
                                        height: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="name" style={{ fontFamily: 'Poppins' }}>Name</MenuItem>
                                    <MenuItem value="brandCategory" style={{ fontFamily: 'Poppins' }}>Brand Category</MenuItem>
                                    <MenuItem value="itemType" style={{ fontFamily: 'Poppins' }}>Item Types</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {"name".includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                            }}
                                            sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            }}
                                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"itemType".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Item Type</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {itemTypes?.map((item: ItemType) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {"brandCategory".includes(searchType) && (
                                <div>
                                    <h2 className='text-black dark:text-white font-Poppins text-xs'>Select Brand Category</h2>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Select
                                            value={searchValue}
                                            onChange={handleNameChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                                className: 'dark:text-white text-black',
                                                }}
                                                sx={{
                                                    width: 300,
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '0.5rem',
                                                        height: '44px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                    },
                                                    '& .MuiSelect-select': {
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                    padding: '10px 14px',
                                                    },
                                                }}
                                            className='bg-slate-100 dark:bg-[#202829] mt-2'
                                        >
                                        {brandCategories?.map((item: BrandCategory) => (
                                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {productCategoryList?.map((productCategory: ProductCategory) =>(
                <TableRow key={productCategory.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                    <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{productCategory.itemTypeName}</TableCell>
                    <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{productCategory.brandCategoryName}</TableCell>
                    <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{productCategory.name}</TableCell>
                    <TableCell align='center' className="capitalize text-gray-800 dark:text-white">
                        <div className='space-x-2'>
							{cred?.hasEdit === 1 &&
								<Tooltip title="Edit" arrow>
									<button className="edit-btn" onClick={() => handleEditClick(productCategory.id)}>
										<FaEdit />
									</button>
								</Tooltip>
							}
							{cred?.hasDelete === 1 &&
								<Tooltip title="Delete" arrow>
									<button className="del-btn" onClick={() => handleDeleteClick(productCategory.id)}>
										<MdDeleteForever />
									</button>
								</Tooltip>
							}
                        </div>
                    </TableCell>
                </TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Product Category?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};
