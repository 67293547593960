import { useEffect, useState } from 'react';
import {
	TableCell, TableRow, TextField, Tooltip,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CommonTable from "../CommonTable";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';
import { queryConfigs } from '../../react-query/queryConfig';
import { BrandCategory, LoginResult } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useGetQuery, useMutationQuery } from '../../react-query/queryHook';
import DeleteCard from "../common/Delete";

const userHeaderNames: string[] = [ "Brand Category Name", "" ];
const limit = 10;

export default function BrandCategories({ pageTitle, cred }: { pageTitle: string, cred: LoginResult | null }) {
    const { queryFn: getBrandCategories, queryKey: brandCategoryKey } = queryConfigs.getBrandCategories;
    const { mutationFn: deleteBrandCategory } = queryConfigs.deleteBrandCategory;
    const [searchValue, setSearchValue] = useState("");
    const [searchForm, setSearchForm] = useState({ name: "" });
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [brandCategoryToDelete, setBrandCategoryToDelete] = useState<number | null>(null);
    const [brandCategoryList, setBrandCategoryList] = useState<BrandCategory[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const { theme } = useTheme();
    const onSuccess = () => {
        showNotification("success", "Brand Category Deleted");
        setIsDeleteDialogOpen(false);
    };

    const { data: brandCategories, refetch } = useGetQuery({
        key: brandCategoryKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            name: searchForm.name ?? "",
        },
        func: getBrandCategories,
    });

    const mutateDelete = useMutationQuery({ key: brandCategoryKey, func: deleteBrandCategory, onSuccess });

    useEffect(() => {
        if (brandCategories) {
            const list = brandCategories?.result?.list as BrandCategory[];
            setBrandCategoryList(list);
        }
    }, [brandCategories]);

    const handleAddClick = () => {
        navigate('add-categoryBrand');
    };

    const handleEditClick = (id: number) => {
        navigate(`edit-categoryBrand/${id}`);
    };

    const handleDeleteClick = (id: number) => {
        setBrandCategoryToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (brandCategoryToDelete) {
            mutateDelete.mutate(brandCategoryToDelete);
        }
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
    	setSearchForm({
            name: searchValue ?? ""
        });
    };
    
    const handleSearchClear = () => {
    	setSearchValue("");
        setSearchForm({ name: "" });
    };

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(brandCategories?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                onAddClick={handleAddClick}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				marginTop={8}
				reload={handleReload}
				cred={cred}
                children1={
                    <>
                      <div className=" flex items-center space-x-5 w-full">
                        <TextField
                          variant="outlined"
                          placeholder='Enter Brand Category Name'
                          name="name"
                          value={searchValue}
                          onChange={handleNameChange}
                          size="small"
                          inputProps={{
                            className: 'dark:text-white text-black',
                            }}
                            sx={{
                            width: 300,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.5rem',
                                height: '44px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                            }}
                            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                        />      
                        <button onClick={handleSearch} className="search-btn">
                          Search
                        </button>
                        <button onClick={handleSearchClear} className="clear-btn">
                          Clear
                        </button>
                      </div>
                    </>
                  }
            >
                {brandCategoryList?.map((brandCategory: BrandCategory) => (
					<TableRow key={brandCategory.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
						<TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{brandCategory.name}</TableCell>
						<TableCell align='center' className="capitalize text-gray-800 dark:text-white">
							<div className='space-x-2'>
								{cred?.hasEdit === 1 &&
									<Tooltip title="Edit" arrow>
										<button className="edit-btn" onClick={() => handleEditClick(brandCategory.id)}>
											<FaEdit />
										</button>
									</Tooltip>
								}
								{cred?.hasDelete === 1 &&
									<Tooltip title="Delete" arrow>
										<button className="del-btn" onClick={() => handleDeleteClick(brandCategory.id)}>
											<MdDeleteForever />
										</button>
									</Tooltip>
								}
							</div>
						</TableCell>
					</TableRow>
                ))}
            </CommonTable>
			<DeleteCard
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteCancel}
				theme={theme}
				text="Are you sure you want to delete this Brand Category?"
				handleConfirm={handleDeleteConfirm}
				handleCancel={handleDeleteCancel}
			/>
        </div>
    );
};
