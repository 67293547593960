import { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { queryConfigs } from '../../react-query/queryConfig';
import { ProductCategoryPayload } from '../../lib/payload';
import { BrandCategory, ItemType, ProductCategory } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { getBrandCategories, getItemTypes, getProductCategory } from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function EditProductCategories() {
    const { mutationFn, queryKey } = queryConfigs.updateProductCategory
    const [formData, setFormData] = useState<ProductCategoryPayload | null>(null);
    const [ itemTypes, setItemTypes ] = useState<ItemType[]>([]);
    const [ brandCategories, setBrandCategories ] = useState<BrandCategory[]>([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams ();
    const onSuccess = () => {
            showNotification("success", "Product Category Updated");
            navigate(-1);
        };

    const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        if (params.id) {
            fetchProductCategory(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchProductCategory = async (id: number) => {
        try {
            const response = await getProductCategory(id);
            if (response?.success) {
                const result = response?.result as ProductCategory;
                setFormData({
                    name: result.name,
                    itemType: result.itemType,
                    brandCategory: result.brandCategory
                });
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get item Type  details");
        }
    };

    useEffect(() => {
        fetchItemTypes();
        fetchBrandCategories();
    }, []);

    const fetchItemTypes = async () => {
        try {
            const response = await getItemTypes({offset:0 , limit: 1000});
            if (response?.success) {
                setItemTypes (response?.result?.list as ItemType [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchBrandCategories = async () => {
        try {
            const response = await getBrandCategories({offset:0 , limit: 1000});
            if (response?.success) {
                setBrandCategories (response?.result?.list as BrandCategory [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value as string,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
        if (params.id && formData && validate(formData, "productCategory")) {
            formData.itemType = sanitizeValue(formData.itemType);
            formData.brandCategory = sanitizeValue(formData.brandCategory);
            updateMutation.mutate({id: params?.id, body: formData});
        }
        else {
            showNotification("error", "Missing Required Field");
        }
    };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Edit Product Category
			</span>
		</div>
        {formData ? (
            <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
                <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Item Type <span className='text-red-400'>*</span></h2>
                <FormControl fullWidth>
                <Select
                    name="itemType"
                    value={formData?.itemType}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{
                        className: 'dark:text-white text-black',
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '0.5rem',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        },
                        '& .MuiSelect-select': {
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        },
                        '& .MuiSelect-icon': {
                        color: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-input': {
                        padding: '10px 14px',
                        },
                    }}
                    className='bg-slate-100 dark:bg-[#202829]'
                    >
                        {itemTypes?.map((item: ItemType) => (
                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </div>
                <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Brand Category <span className='text-red-400'>*</span></h2>
                <FormControl fullWidth>
                    <Select
                    name="brandCategory"
                    value={formData?.brandCategory}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{
                        className: 'dark:text-white text-black',
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: 'auto',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        },
                        '& .MuiSelect-select': {
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        },
                        '& .MuiSelect-icon': {
                        color: theme.palette.text.primary,
                        },
                        '& .MuiInputLabel-root': {
                        color: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-input': {
                        padding: '10px 14px',
                        },
                    }}
                    className='bg-slate-100 dark:bg-[#202829] rounded-3xl'
                    >
                        {brandCategories?.map((item: BrandCategory) => (
                            <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </div>
                <div>
                <h2 className="text-sm font-Poppins font-base mb-2">Product Category <span className='text-red-400'>*</span></h2>
                <TextField
                    name="name"
                    placeholder="Enter Product Category Name"
                    variant="outlined"
                    value={formData?.name}
                    onChange={handleInputChange}
                    inputProps={{
                    className: 'dark:text-white text-black',
                    }}
                    sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                        height: '44px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    }}
                    className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                />
                </div>
            </form>
        ) : (
            <p>Loading</p>
        )}
        <div className='flex justify-center mt-8 space-x-12 items-center'>
        <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                    <span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
                </button>
                <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                    <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                    <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                    <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                    <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
            </div>
    </main>
  );
};
