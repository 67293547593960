import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrency } from '../../lib/api';
import { Currency } from '../../lib/models';
import { CurrencyPayload } from '@/lib/payload';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { queryConfigs } from '../../react-query/queryConfig';
import { useMutationQuery } from '../../react-query/queryHook';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";

export default function EditCurrency() {
    const {mutationFn, queryKey } = queryConfigs.updateCurrency
    const [ formData, setFormData ] = useState<CurrencyPayload | null>(null);
    const navigate = useNavigate();
    const params = useParams ();
    const onSuccess = () => {
        showNotification("success", "Currency Updated");
        navigate(-1);
    };

    const updateMutation = useMutationQuery ({key: queryKey, func: mutationFn, onSuccess});

    useEffect(() => {
        if (params.id) {
            fetchCurrency(sanitizeValue(params.id));
        }
    }, [params]);

    const fetchCurrency = async (id: number) => {
        try {
            const response = await getCurrency(id);
            if (response?.success) {
                const result = response?.result as Currency;
                setFormData({
                    currency: result.currency,
                    conversionRate: result.conversionRate,
                });
            }
        } catch (err) {
            console.error(err);
            showNotification("error", "Unable to get Currency details");
        }
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (params.id && formData && validate(formData, "currency")) {
            formData.conversionRate = sanitizeValue(formData.conversionRate);
            updateMutation.mutate({id: params?.id, body: formData});
        }
        else {
            showNotification("error", "Missing Required Field");
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

  return (
    <main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
		<div className="flex flex-start">
			<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
			<span className="text-left text-2xl font-Poppins font-semibold ml-5">
				Edit Currency
			</span>
		</div>
        <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-4 py-6 justify-center items-center'>
        <div>
        <h2 className="text-sm font-Poppins font-base mb-2">Currency <span className='text-red-400'>*</span></h2>
        <TextField
            name="currency"
            placeholder="Enter Currency"
            variant="outlined"
            value={formData?.currency}
            onChange={handleInputChange}
            inputProps={{
            className: 'dark:text-white text-black',
            }}
            sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                height: '44px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
            }}
            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
        />
        </div>
        <div>
        <h2 className="text-sm font-Poppins font-base mb-2">Forex Conversion Rate to PHP <span className='text-red-400'>*</span></h2>
        <TextField
            name="conversionRate"
            placeholder="Enter Conversion Rate"
            variant="outlined"
            value={formData?.conversionRate}
            onChange={handleInputChange}
            inputProps={{
            className: 'dark:text-white text-black',
            }}
            sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                height: '44px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
            }}
            className='bg-slate-100 dark:bg-[#202829] rounded-lg'
        />
        </div>
    </form>
        <div className='flex justify-center mt-8 space-x-12 items-center'>
            <button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
                <span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
            </button>
            <button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
        </div>
    </main>
  );
}
