import { useEffect, useState } from 'react';
import {
	FormControl, MenuItem, Select, TableCell, TableRow, TextField,
	Box, Modal,
} from '@mui/material';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import { queryConfigs } from '../../react-query/queryConfig';
import { useTheme } from '../../ThemeContext';
import { sanitizeValue } from '../../lib/helper';
import { useGetQuery } from '../../react-query/queryHook';
import { Transfer, Warehouse, LoginResult } from '../../lib/models';
import { getExportURL, getWarehouses } from "../../lib/api";
import Import from "../Import";

const userHeaderNames: string[] = [
    "Date", "From", "To", "Item Code",
    "Parent Code", "Item Name", "Uom Group",
    "PO Quantity", "Quantity in Pieces",
    "ITR Number",
];

const limit = 10;

export default function Transfers({ pageTitle, cred }: { pageTitle : string, cred: LoginResult | null }) {
    const { queryFn: getTransfers, queryKey: transferKey } = queryConfigs.getTransfers;
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState("itemName");
    const [searchForm, setSearchForm] = useState({
        fromWarehouse: "", toWarehouse: "", itemCode: "",
        itemName: "", itrNumber:"", startDate: "", endDate: "",
    });
    const navigate = useNavigate();
    const [transferList, setTransferList] = useState<Transfer[]>([])
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])
    const [currentPage, setCurrentPage] = useState(1);
	const [openImport, setOpenImport] = useState(false);
    const { theme } = useTheme();

	const boxStyles = {
		position: 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: theme === 'dark' ? '#20293D' : 'white',
		color: theme === 'dark' ? 'white' : 'black',
		borderRadius: '10px',
		boxShadow: 24,
	};

    const { data: transfers, refetch } = useGetQuery({
        key: transferKey,
        params: {
            offset: (currentPage - 1) * limit,
            limit,
            fromWarehouse: searchForm?.fromWarehouse ?? "",
            toWarehouse: searchForm?.toWarehouse ?? "",
            itemName: searchForm?.itemName ?? "",
            itemCode: searchForm?.itemCode ?? "",
            startDate: searchForm?.startDate ?? "",
            endDate: searchForm?.endDate ?? "",
            itrNumber: searchForm?.itrNumber ?? "",
        },
        func: getTransfers,
    });

    useEffect(() => {
        fetchWarehouses();
    }, []);

    const fetchWarehouses = async () => {
        try {
            const response = await getWarehouses({offset:0 , limit: 1000});
            if (response?.success) {
                setWarehouses (response?.result?.list as Warehouse [] ?? []);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (transfers) {
            const list = transfers?.result?.list as Transfer[];
            setTransferList(list);
        }
    }, [transfers]);

	const handleReload = () => {
		refetch();
		handleSearchClear();
	};

    const handleNameChange = (e: any) => {
        setSearchValue(e.target.value)
    };
    
    const handleSearch = () => {
        setSearchForm({
            fromWarehouse: searchType === "fromWarehouse" ? searchValue : searchForm.fromWarehouse,
            toWarehouse: searchType === "toWarehouse" ? searchValue : searchForm.toWarehouse,
            itemName: searchType === "itemName" ? searchValue : "",
            itemCode: searchType === "itemCode" ? searchValue : "",
            itrNumber: searchType === "itrNumber" ? searchValue : "",
            startDate: searchType === "startDate" ? searchValue : "",
            endDate: searchType === "endDate" ? searchValue : "",
        });    
    };
    
    const handleSearchClear = () => {
        setSearchValue("");
        setSearchType("itemName");
		setSearchForm({
			itemName: "", itemCode: "", fromWarehouse: "", toWarehouse: "", itrNumber: "",
			startDate: "", endDate: "",
		});
    };

    const handleTypeChange = (e: any) => {
        setSearchType(e.target.value)
    };

    const handleWarehouseChange = (e: any, type: 'fromWarehouse' | 'toWarehouse') => {
        setSearchForm((prevState) => ({
            ...prevState,
            [type]: e.target.value,
        }));
    };

    const handleAddClick = () => {
        navigate('add-transfer');
    };

	const exportHandler = () => {
		let url: string = getExportURL("transfers");
		url += `
			?itemCode=${searchForm?.itemCode ?? ""}&itemName=${searchForm?.itemName ?? ""}&
			itrNumber=${searchForm?.itrNumber ?? ""}&fromWarehouse=${searchForm?.fromWarehouse ?? ""}
		`;
		window.open(url, "_blank");
	};

    return (
        <div className='w-full overflow-hidden mx-auto relative'>
            <CommonTable
                tableHeaders={userHeaderNames}
                totalRows={sanitizeValue(transfers?.result?.count)}
                colSpan={userHeaderNames.length}
                pageTitle={pageTitle}
                marginTop={8}
                componentType='transfer'
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				reload={handleReload}
				onExportClick={exportHandler}
                onAddClick={handleAddClick}
				onImportClick={() => setOpenImport(true)}
				cred={cred}
                children1={
                    <div className='w-full flex items-center gap-4'>
                        <div>
                            <h2 className='text-black dark:text-white font-Poppins text-xs'>Filter</h2>
                            <FormControl fullWidth>
                                <Select value={searchType} onChange={handleTypeChange}
                                    inputProps={{
                                    className: 'dark:text-white text-black',
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '14px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.5rem',
                                            height: '44px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                        	borderColor: 'transparent',
                                        },
                                        '& .MuiSelect-select': {
											height: 'auto',
											display: 'flex',
											alignItems: 'center',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                        	padding: '10px 14px',
                                        },
                                    }}
                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                >
                                    <MenuItem value="itemName" style={{ fontFamily: 'Poppins' }}>Item Name</MenuItem>
                                    <MenuItem value="itemCode" style={{ fontFamily: 'Poppins' }}>Item Code</MenuItem>
                                    <MenuItem value="itrNumber" style={{ fontFamily: 'Poppins' }}>ITR Number</MenuItem>
                                    <MenuItem value="warehouse" style={{ fontFamily: 'Poppins' }}>Warehouse</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex items-center space-x-5 w-full">
                            {["itemName", "itemCode", "itrNumber"].includes(searchType) && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter to search"
                                        value={searchValue}
                                        onChange={handleNameChange}
                                        size="small"
                                        inputProps={{
                                            className: 'dark:text-white text-black',
                                        }}
                                        sx={{
                                            width: 300,
                                            marginTop: 3,
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.5rem',
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                        }}
                                        className='bg-slate-100 dark:bg-[#202829] rounded-lg'
                                    />
                                </div>
                            )}
                            {"warehouse".includes(searchType) && (
                                <div className='flex gap-4'>
                                    <div className='flex-col'>
                                        <h2 className='text-black dark:text-white font-Poppins text-xs'>From Warehouse</h2>
                                        <FormControl sx={{ width: "50%" }}>
                                            <Select
                                                value={searchForm.fromWarehouse}
                                                onChange={(e) => handleWarehouseChange(e, 'fromWarehouse')}
                                                size="small"
                                                displayEmpty
                                                inputProps={{
                                                    className: 'dark:text-white text-black',
                                                }}
                                                sx={{
													width: 300,
													fontFamily: 'Poppins, sans-serif',
													fontSize: '14px',
													'& .MuiOutlinedInput-root': {
														borderRadius: '0.5rem',
														height: '44px',
													},
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: 'transparent',
													},
													'& .MuiSelect-select': {
														height: 'auto',
														display: 'flex',
														alignItems: 'center',
													},
													'& .MuiOutlinedInput-input': {
														padding: '10px 14px',
													},
                                                }}
                                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                            >
                                            {warehouses?.map((item: Warehouse) => (
                                                <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='flex flex-col'>
                                        <h2 className='text-black dark:text-white font-Poppins text-xs'>To Warehouse</h2>
                                        <FormControl sx={{ width: "50%" }}>
                                            <Select
                                                value={searchForm.toWarehouse}
                                                onChange={(e) => handleWarehouseChange(e, 'toWarehouse')}
                                                size="small"
                                                displayEmpty
                                                inputProps={{
                                                    className: 'dark:text-white text-black',
                                                }}
                                                sx={{
													width: 300,
													fontFamily: 'Poppins, sans-serif',
													fontSize: '14px',
													'& .MuiOutlinedInput-root': {
														borderRadius: '0.5rem',
														height: '44px',
													},
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: 'transparent',
													},
													'& .MuiSelect-select': {
														height: 'auto',
														display: 'flex',
														alignItems: 'center',
													},
													'& .MuiOutlinedInput-input': {
														padding: '10px 14px',
													},
                                                }}
                                                className='bg-slate-100 dark:bg-[#202829] mt-2'
                                            >
                                            {warehouses?.map((item: Warehouse) => (
                                                <MenuItem key={item.id} value={item.id} sx={{fontFamily: 'Poppins, sans-serif'}}>{item.code}</MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            <button onClick={handleSearch} className="search-btn mt-6">
                                Search
                            </button>
                            <button onClick={handleSearchClear} className="clear-btn mt-6">
                                Clear
                            </button>
                        </div>
                    </div>
                }
            >
                {transferList?.map((transfer: Transfer) =>(
                    <TableRow key={transfer.id} style={{height: '60px' }} className='bg-white dark:bg-[#101422]'>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">
                        {new Date(transfer?.createdOn).toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric', }).replace(/(\s)(\d{4})$/, ', $2')}
                        </TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{transfer.fromWarehouseCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600}} className="capitalize text-gray-800 dark:text-white">{transfer.toWarehouseCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.itemCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.parentCode}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px',fontWeight: 600, width: '300px',}} className="capitalize text-gray-800 dark:text-white">{transfer.itemName}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.uomGroup}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.poQuantity}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.pieces}</TableCell>
                        <TableCell align='center' sx={{fontSize:'13px'}} className="capitalize text-gray-800 dark:text-white">{transfer.itrNumber}</TableCell>
                    </TableRow>
                ))}
            </CommonTable>
			<Modal open={openImport} onClose={() => setOpenImport(false)}>
				<Box sx={boxStyles} >
					<Import fileType="transfers" refresh={refetch} onClose={() => setOpenImport(false)} />
				</Box>
			</Modal>
        </div>
    );
};
