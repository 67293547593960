import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { DemandBodyPayload, DemandPayload } from '../../lib/payload';
import { queryConfigs } from '../../react-query/queryConfig';
import { Demand } from '../../lib/models';
import { sanitizeValue, showNotification } from '../../lib/helper';
import { useMutationQuery } from '../../react-query/queryHook';
import { getAverageDemands } from '../../lib/api';
import { validate } from '../../lib/validation';
import { IoMdArrowBack } from "react-icons/io";
import { useTheme } from '../../ThemeContext';

export default function EditAvgDemand() {
	const { mutationFn, queryKey } = queryConfigs.updateAverageDemand;
	const [demands, setDemands] = useState<Demand[]>([]);
	const navigate = useNavigate();
	const location = useLocation();
	const {theme} = useTheme();
	const inputStyle = {
		width: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: '0.5rem',
			height: 'auto',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	};

	const queryParams = new URLSearchParams(location.search);
	const month: string = queryParams.get("month") ?? "";
	const year: string = queryParams.get("year") ?? "";

  	const onSuccess = () => {
    	showNotification("success", "Average Demand Updated");
    	navigate(-1);
  	};

  	const updateMutation = useMutationQuery({ key: queryKey, func: mutationFn, onSuccess });

	useEffect(() => {
		const monthValue: number = sanitizeValue(parseInt(month));
		const yearValue: number = sanitizeValue(parseInt(year))
		if (month && year) {
			fetchAvgDemand(monthValue, yearValue);
		}
	}, [month, year]);

  	const fetchAvgDemand = async (month: number, year: number) => {
    	try {
      		const response = await getAverageDemands({ month, year });
			if (response?.success) {
				const list = response?.result?.list as Demand[] ?? [];
        		setDemands(list);
			} else {
				showNotification("error", response?.message ?? "Unable to get demand details");
			}
    	} catch (err) {
      		console.error(err);
      		showNotification("error", "Unable to get demand details");
    	}
	};

  	const handleInputChange = (index: number, e: any) => {
    	const { name, value } = e.target;
    	const updatedFormData = [...demands];
    	updatedFormData[index] = {
      		...updatedFormData[index],
      		[name]: value,
    	};
    	setDemands(updatedFormData);
  	};

  	const handleSubmit = (e: any) => {
		e.preventDefault();
		const formData: DemandPayload[] = [];
		for (const demand of demands) {
			const obj: DemandPayload = {
				id: sanitizeValue(demand?.id),
				firstMonth: sanitizeValue(demand?.firstMonth),
				secondMonth: sanitizeValue(demand?.secondMonth),
				thirdMonth: sanitizeValue(demand?.thirdMonth),
			};
			formData.push(obj);
		}
		for (const data of formData) {
			if (!validate(data, "demandUpdate")) {
				showNotification("error", "Missing Required Field");
				return;
			}
		}
    	const form: DemandBodyPayload = { demands: formData };	
		updateMutation.mutate(form);
    };

  	const handleCancel = () => {
		navigate(-1);
  	};

	return (
		<main className="mt-20 bg-white dark:bg-[#141729] p-8 rounded-lg shadow-md">
			<div className="flex flex-start">
				<button onClick={() => navigate(-1)} className="text-2xl"><IoMdArrowBack /></button>
				<span className="text-left text-2xl font-Poppins font-semibold ml-5">
          			Edit Average Demand
        		</span>
			</div>
			<form onSubmit={handleSubmit} className="py-6">
				<table className="min-w-full table-auto border-separate border-spacing">
					<thead>
						<tr>
						  <th className="p-2 text-sm font-Poppins text-left">Brand Code</th>
						  <th className="p-2 text-sm font-Poppins text-left">Parent Code</th>
						  <th className="p-2 text-sm font-Poppins text-left">Item Name</th>
						  <th className="p-2 text-sm font-Poppins text-left">First Month</th>
						  <th className="p-2 text-sm font-Poppins text-left">Second Month</th>
						  <th className="p-2 text-sm font-Poppins text-left">Third Month</th>
						</tr>
				  </thead>
				  <tbody>
					  {demands?.map((demand: Demand, index: number) => (
						  <tr key={demand?.id} className="text-left">
							  <td className="p-2 text-xs font-Poppins text-left">{demand?.brandCode}</td>
							  <td className="p-2 text-xs font-Poppins text-left">{demand?.parentCode}</td>
							  <td className="p-2 text-xs font-Poppins text-left">{demand?.itemName}</td>
							  <td className="p-2 text-xs font-Poppins text-left">
								  <TextField
									  name="firstMonth"
									  value={demand?.firstMonth ?? ""}
									  onChange={(e) => handleInputChange(index, e)}
									  fullWidth
									  inputProps={{ className: theme === 'dark' ? 'dark:text-white' : 'text-black' }}
									  sx={inputStyle}
									  className="bg-slate-100 dark:bg-[#202829] rounded-lg"
								  />
							  </td>
							  <td className="p-2 text-xs font-Poppins text-left">
								  <TextField
									  name="secondMonth"
									  value={demand?.secondMonth ?? ""}
									  onChange={(e) => handleInputChange(index, e)}
									  fullWidth
									  inputProps={{ className: theme === 'dark' ? 'dark:text-white' : 'text-black' }}
									  sx={inputStyle}
									  className="bg-slate-100 dark:bg-[#202829] rounded-lg"
								  />
								</td>
								<td className="p-2 text-xs font-Poppins text-left">
									<TextField
										name="thirdMonth"
										value={demand?.thirdMonth ?? ''}
										onChange={(e) => handleInputChange(index, e)}
										fullWidth
										inputProps={{ className: theme === 'dark' ? 'dark:text-white' : 'text-black' }}
										sx={inputStyle}
										className="bg-slate-100 dark:bg-[#202829] rounded-lg"
									/>
							</td>
						</tr>
					))}
				 </tbody>
			</table>
		</form>
		<div className='flex justify-center mt-8 space-x-12 items-center'>
			<button onClick={handleSubmit} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
				<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-blue-500 opacity-[3%]"></span>
				<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-blue-500 opacity-100 group-hover:-translate-x-8"></span>
				<span className="relative w-full text-left text-blue-500 transition-colors duration-200 ease-in-out group-hover:text-white">Save</span>
				<span className="absolute inset-0 border-2 border-blue-500 rounded-lg"></span>
			</button>
			<button onClick={handleCancel} className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group">
				<span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
				<span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
				<span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">Cancel</span>
				<span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
			</button>
		</div>
     </main>
 	);
}
